<div class="terminList container">
    <h3>Terminkalender</h3>

    <div *ngIf="isAdmin()" class="form-group">
        <label for="saisonSelect">Saison</label>
        <select id="saisonSelect" class="form-control"
                [compareWith]="compareById"
                [(ngModel)]="selectedSaison" name="saison"
                (change)="onSaisonChange()">
            <option *ngFor="let saison of saisonList"
                    [ngValue]="saison">{{saison.bezeichnung}}</option>
        </select>
    </div>

    <div class="d-md-none" *ngIf="terminList">
        <table class="table table-sm">
            <thead>
                <tr class="d-flex">
                    <th class="col-12">Datum/Ort/Termin</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let termin of terminList" class="d-flex">
                    <td [ngClass]="{'col-9 col-sm-10': isAdmin(), 'col-12': !isAdmin()}">
                        <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.MM.yyyy'}}, {{termin.zeit}}</div>
                        <div class="row m-0 text-muted">{{termin.ort}}</div>
                        <div class="row m-0">{{termin.anlass}}</div>
                        <div *ngIf="termin.alert">
                            <app-information-alert [information]="termin.alert" small="true"></app-information-alert>
                        </div>
                    </td>
                    <td *ngIf="isAdmin()" class="col-3 col-sm-2">
                        <button class="btn btn-danger px-1 py-0 ml-1 float-right" [disabled]="!canBeDeleted(termin)" (click)="deleteTermin(termin.id)">
                            <fa-icon [icon]="faTrash" size="s"></fa-icon>
                        </button>
                        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
                          [routerLink]="['/saisons', selectedSaison.id, 'termine', termin.id.id]"
                           routerLinkActive="active">
                            <fa-icon [icon]="faEdit" size="s"></fa-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-none d-md-block" *ngIf="terminList">
        <table class="table table-sm">
            <thead>
                <tr class="d-flex">
                    <th class="col-3">Datum/Ort</th>
                    <th class="col-6 col-lg-7">Termin</th>
                    <th class="col-3 col-lg-2">Juniorenschach</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let termin of terminList" class="d-flex">
                    <td class="col-3">
                        <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.M.yyyy'}}, {{termin.zeit}}</div>
                        <div class="row m-0 text-muted">{{termin.ort}}</div>
                    </td>
                    <td class="col-6 col-lg-7">
                        <div class="row m-0">{{termin.anlass}}</div>
                        <div class="row m-0 text-muted">{{termin.bemerkung}}</div>
                        <div *ngIf="termin.alert">
                            <app-information-alert [information]="termin.alert" [small]="true"></app-information-alert>
                        </div>
                    </td>
                    <td class="col-1">
                      <span *ngIf="termin.istJuniorenschach">Ja</span>
                      <span *ngIf="!termin.istJuniorenschach">Nein</span>
                    </td>
                    <td class="col-2 col-lg-1">
                        <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 ml-1 float-right" [disabled]="!canBeDeleted(termin)" (click)="deleteTermin(termin.id)">
                            <fa-icon [icon]="faTrash" size="s"></fa-icon>
                        </button>
                        <a *ngIf="isAdmin()"  class="btn btn-secondary px-1 py-0 ml-1 float-right"
                           [routerLink]="['/saisons', selectedSaison.id, 'termine', termin.id]"
                           routerLinkActive="active">
                            <fa-icon [icon]="faEdit" size="s"></fa-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <a *ngIf="isAdmin() && selectedSaison" class="btn btn-primary"
       [routerLink]="['/saisons', selectedSaison.id, 'termine', 'new']"
       routerLinkActive="active">
        <fa-icon [icon]="faPlus" size="s"></fa-icon>
    </a>
</div>
