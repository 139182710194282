import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Mitglied } from './mitglied';
import { Elozahl } from './elozahl';
import { StatusService } from '../status/status.service';


@Injectable({
  providedIn: 'root'
})
export class MitgliedService {

  private urlPrefix = '/api/person';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private statusService: StatusService) { }

  getMitgliedList$(): Observable<Mitglied[]> {
    let url = `${this.urlPrefix}`;
    return this.http.get<Mitglied[]>(url)
      .pipe(
        catchError(this.handleError<Mitglied[]>('getMitgliedList', []))
      );
  }

  getMitglied$(mitgliedId: number): Observable<Mitglied> {
    let url = `${this.urlPrefix}/${mitgliedId}`;
    return this.http.get<Mitglied>(url)
      .pipe(
        catchError(this.handleError<Mitglied>('getMitglied'))
      );
  }

  getElozahlen$(mitgliedId: number): Observable<Elozahl[]> {
    let url = `${this.urlPrefix}/${mitgliedId}/elo`;
    return this.http.get<Elozahl[]>(url)
      .pipe(
        catchError(this.handleError<Elozahl[]>('getElozahlen'))
      );
  }

  saveMitglied$(mitglied: Mitglied): Observable<Mitglied> {
    let url = `${this.urlPrefix}`;
    if (mitglied.id || mitglied.id===0) {
      return this.http.put<Mitglied>(url, mitglied, this.httpOptions)
        .pipe(
          catchError(this.handleError<Mitglied>('updateMitglied'))
        );

    } else {
      return this.http.post<Mitglied>(url, mitglied, this.httpOptions)
        .pipe(
          catchError(this.handleError<Mitglied>('insertMitglied'))
        );
    }
  }

  deleteMitglied$(mitgliedId: number): Observable<Object> {
    let url = `${this.urlPrefix}/${mitgliedId}`;
    return this.http.delete(url, this.httpOptions)
      .pipe(
        catchError(this.handleError<Mitglied>('deleteMitglied'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.logError(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private logError(message: string) {
    this.statusService.add(message);
  }
}
