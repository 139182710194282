import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Saison } from '../../saison/saison';
import { Termin } from '../termin';
import { TerminService } from '../termin.service';
import { Information } from '../../information/information';
import { InformationService } from '../../information/information.service';

@Component({
  selector: 'app-termin-edit',
  templateUrl: './termin-edit.component.html',
  styleUrls: ['./termin-edit.component.scss']
})
export class TerminEditComponent implements OnInit {

  termin: Termin;
  saisonId: number;

  informationList: Information[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private terminService: TerminService,
    private informationService: InformationService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
            this.saisonId = params['saisonId'];
            const terminId = params['terminId'];
            if (isNaN(terminId)) {
              this.newTermin();
            } else {
              this.readTermin(+terminId);
            }
        }
      );

    this.readInformationList();
  }

  private readTermin(terminId: number) {
      this.terminService.getTermin(terminId)
        .subscribe((termin) => this.termin = termin);
  }

  private newTermin() {
      this.termin = {
        id: null,
        fkSaison: this.saisonId,
        wochentag: 'Di',
        datum: null,
        zeit: null,
        ort: null,
        anlass: null,
        istJuniorenschach: false,
        bemerkung: null,
        alert: null
      }
  }

  readInformationList(): void {
    this.informationService.getInformationList()
      .subscribe(informationList => {
          this.informationList = [];
          this.informationList.push (null);
          Array.prototype.push.apply(this.informationList, informationList);
        }
      );
  }

  compareById(information1: Information, information2: Information) {
    if (information1 && information2) {
      return information1.id == information2.id;
    } else {
      return false;
    }
  }

  onSubmit() {
    this.terminService.saveTermin(this.termin).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['saisons', this.saisonId, 'termine']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
