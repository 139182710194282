<div *ngIf="turnierserie && saison && einzelturnier" class="turnierSerieDetailEdit container">
  <h3>
    <span class="d-none d-sm-inline">{{turnierserie.bezeichnung}} ({{saison.bezeichnung}}): </span>
    <span>{{einzelturnier.bezeichnung}} ({{einzelturnier.datum | date:'dd.MM.yyyy'}})</span>
  </h3>

  <table class="table table-sm">
    <thead>
    <tr class="d-flex thead-light">
      <th class="col-5">Spieler</th>
      <th class="col-3">Resultat</th>
      <th class="col-4"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let rangierung of einzelturnier.rangliste">
      <td class="col-5">{{rangierung.vorname}} {{rangierung.name}}</td>
      <td class="col-3">{{rangierung.resultat}}</td>
      <td class="col-4">
        <button class="btn btn-danger px-1 py-0 ml-1 float-right"
                title="Löschen"
                (click)="deleteRangierung(rangierung)">
          <fa-icon [icon]="faTrash" size="s"></fa-icon>
        </button>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-detail-edit', einzelturnier.id, 'rangierung-edit', rangierung.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faEdit" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    <tr class="d-flex">
      <td class="col-5">
        <a class="btn btn-primary btn-sm"
           title="Rangierung hinzufügen"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-detail-edit', einzelturnier.id, 'rangierung-edit','new']"
           routerLinkActive="active">
          <fa-icon [icon]="faPlus" size="s"></fa-icon>
        </a>
      </td>
      <td class="col-3"></td>
      <td class="col-4">
        <a class="btn btn-secondary btn-sm float-right"
           title="Zurück"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'turnierserie-detail-edit']"
           routerLinkActive="active">
          <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    </tbody>
  </table>

</div>
