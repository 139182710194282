<div class="mitgliedList container">
  <h3>Mitglieder-Liste</h3>
  <div *ngIf="isAdmin()">
    <input type="checkbox" class="form-check-input ml-0" id="activeCheck"
           [(ngModel)]="showInactive" name="showInactive">
    <label for="activeCheck" class="ml-3">Inaktive anzeigen?</label>
  </div>
  <h6>
    <div *ngIf="!showMemberDetails()">(Für Details zu Adresse, Telefonnummern und E-Mail bitte einloggen)</div>
  </h6>

  <div *ngIf="showMemberDetails()" class="d-none d-lg-block">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-4">
          <div class="m-0 p-0">Name</div>
          <div class="m-0 p-0 text-muted">Adresse</div>
        </th>
        <th class="col-2">Telefon</th>
        <th class="col-4">E-Mail</th>
        <th class="col-1">
          <div class="m-0 p-0">ELO</div>
          <div class="m-0 p-0 text-muted">SSB</div>
        </th>
        <th class="col-1"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let mitglied of mitgliedList" class="d-flex">
        <td class="col-4">
          <div class="m-0 p-0">{{mitglied.vorname}} {{mitglied.name}}</div>
          <div class="m-0 p-0 text-muted">{{mitglied.adresse}}, {{mitglied.plz}} {{mitglied.ort}}</div>
        </td>
        <td class="col-2">
          <div *ngIf="mitglied.telPrivat" class="m-0 p-0">P: {{mitglied.telPrivat}}</div>
          <div *ngIf="mitglied.telMobil" class="m-0 p-0">M: {{mitglied.telMobil}}</div>
          <div *ngIf="mitglied.telGeschaeft" class="m-0 p-0">G: {{mitglied.telGeschaeft}}</div>
        </td>
        <td class="col-4">{{mitglied.email}}</td>
        <td class="col-1">
          <div class="m-0 p-0">
            <span *ngIf="mitglied.elo">{{mitglied.elo}}</span>
            <span *ngIf="!mitglied.elo">-</span>
          </div>
          <div class="m-0 p-0 text-muted">{{mitglied.ssbNummer}}</div>
        </td>
        <td class="col-1">
          <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 ml-1 float-right" [disabled]="!canBeDeleted(mitglied)"
                  (click)="deleteMitglied(mitglied.id)">
            <fa-icon [icon]="faTrash" size="s"></fa-icon>
          </button>
          <a *ngIf="isAdmin() || belongsToUser(mitglied)" class="btn btn-secondary px-1 py-0 ml-1 float-right"
             [routerLink]="['/mitglieder', mitglied.id]"
             routerLinkActive="active">
            <fa-icon [icon]="faEdit" size="s"></fa-icon>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="showMemberDetails()" class="d-lg-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-9 col-sm-10">
          <div class="m-0 p-0">Name</div>
          <div class="m-0 p-0 text-muted">Adresse</div>
          <div class="m-0 p-0">Telefon</div>
          <div class="m-0 p-0 text-muted">Email</div>
        </th>
        <th class="col-3 col-sm-2">
          <div class="m-0 p-0">ELO</div>
          <div class="m-0 p-0 text-muted">SSB</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let mitglied of mitgliedList" class="d-flex">
        <td class="col-9 col-sm-10">
          <div class="m-0 p-0">{{mitglied.vorname}} {{mitglied.name}}</div>
          <div class="m-0 p-0 text-muted">{{mitglied.adresse}}, {{mitglied.plz}} {{mitglied.ort}}</div>
          <div *ngIf="mitglied.telPrivat" class="m-0 p-0">Privat: {{mitglied.telPrivat}}</div>
          <div *ngIf="mitglied.telMobil" class="m-0 p-0">Mobil: {{mitglied.telMobil}}</div>
          <div *ngIf="mitglied.telGeschaeft" class="m-0 p-0">Geschäft: {{mitglied.telGeschaeft}}</div>
          <div class="m-0 p-0 text-muted">{{mitglied.email}}</div>
        </td>
        <td class="col-3 col-sm-2 justify-items-center">
          <div class="m-0 p-0">
            <span *ngIf="mitglied.elo">{{mitglied.elo}}</span>
            <span *ngIf="!mitglied.elo">-</span>
          </div>
          <div class="m-0 p-0 text-muted">{{mitglied.ssbNummer}}</div>
          <div>
            <a *ngIf="isAdmin()" class="btn btn-secondary pl-1 pr-0 py-0 my-0"
               [routerLink]="['/mitglieder', mitglied.id]"
               routerLinkActive="active">
              <fa-icon [icon]="faEdit" size="s"></fa-icon>
            </a>
            <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 my-0 mx-1"
                    [disabled]="!canBeDeleted(mitglied)"
                    (click)="deleteMitglied(mitglied.id)">
              <fa-icon [icon]="faTrash" size="s"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!showMemberDetails()" class="d-none d-sm-block">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-1">SSB</th>
        <th class="col-4">Name</th>
        <th class="col-1">ELO</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let mitglied of mitgliedList" class="d-flex">
        <td class="col-1">{{mitglied.ssbNummer}}</td>
        <td class="col-4">{{mitglied.vorname}} {{mitglied.name}}</td>
        <td class="col-1">{{mitglied.elo}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!showMemberDetails()" class="d-sm-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-10">
          <div class="m-0 p-0">Name</div>
        </th>
        <th class="col-2">
          <div class="m-0 p-0">ELO</div>
          <div class="m-0 p-0 text-muted">SSB</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let mitglied of mitgliedList" class="d-flex">
        <td class="col-10">
          <div class="m-0 p-0">{{mitglied.vorname}} {{mitglied.name}}</div>
        </td>
        <td class="col-2">
          <div class="m-0 p-0">
            <span *ngIf="mitglied.elo">{{mitglied.elo}}</span>
            <span *ngIf="!mitglied.elo">-</span>
          </div>
          <div class="m-0 p-0 text-muted">{{mitglied.ssbNummer}}</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <a *ngIf="isAdmin()" class="btn btn-primary"
     [routerLink]="['/mitglieder', 'new']"
     [state]="{}"
     routerLinkActive="active">
    <fa-icon [icon]="faPlus" size="s"></fa-icon>
  </a>
</div>
