<div class="mitgliedEdit container">
  <h2>Mitglied</h2>
  <form *ngIf="mitglied" (ngSubmit)="onSubmit()" #mitgliedEdit="ngForm">
    <div class="form-row">
      <div class="form-group col-6">
        <label for="ssbNummer">SSB-Nummer</label>
        <input *ngIf="isAdmin()" type="text" class="form-control" placeholder="SSB-Nummer"
               id="ssbNummer"
               [(ngModel)]="mitglied.ssbNummer" name="ssbNummer">
        <span class="form-control" *ngIf="!isAdmin()">{{mitglied.ssbNummer}}</span>
      </div>
      <div class="form-group col-3 col-md-2 col-lg-1">
        <label for="elo">Elo</label>
        <!--
        <a *ngIf="isAdmin()" id="elo" class="form-control btn btn-secondary"
           [routerLink]="['/mitglieder', mitglied.id, 'elo']">
          <span *ngIf="mitglied.elo">{{mitglied.elo}}</span>
          <span *ngIf="!mitglied.elo">Elo...</span>
        </a>
        -->
        <span id="elo" class="form-control">{{mitglied.elo}}</span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <label for="vorname">Vorname</label>
        <input type="text" class="form-control" placeholder="Vorname"
               id="vorname"
               [(ngModel)]="mitglied.vorname" name="vorname">
      </div>
      <div class="form-group col-6">
        <label for="name">Name</label>
        <input type="text" class="form-control" placeholder="Name"
               id="name"
               [(ngModel)]="mitglied.name" name="name">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-lg-6">
        <label for="adresse">Adresse</label>
        <input type="text" class="form-control" placeholder="Adresse"
               id="adresse"
               [(ngModel)]="mitglied.adresse" name="adresse">
      </div>
      <div class="form-group col-4 col-lg-1">
        <label for="plz">PLZ</label>
        <input type="text" class="form-control input-sm" placeholder="PLZ"
               id="plz"
               [(ngModel)]="mitglied.plz" name="plz">
      </div>
      <div class="form-group col-8 col-lg-5">
        <label for="ort">Ort</label>
        <input type="text" class="form-control" placeholder="Ort"
               id="ort"
               [(ngModel)]="mitglied.ort" name="ort">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-6 col-lg-4">
        <label for="telPrivat">Telefon Privat</label>
        <input type="text" class="form-control" placeholder="Tel P"
               id="telPrivat"
               [(ngModel)]="mitglied.telPrivat" name="telPrivat">
      </div>
      <div class="form-group col-6 col-lg-4">
        <label for="telMobil">Telefon Mobil</label>
        <input type="text" class="form-control" placeholder="Tel M"
               id="telMobil"
               [(ngModel)]="mitglied.telMobil" name="telMobil">
      </div>
      <div class="form-group col-6 col-lg-4">
        <label for="telGeschaeft">Telefon Geschäft</label>
        <input type="text" class="form-control" placeholder="Tel G"
               id="telGeschaeft"
               [(ngModel)]="mitglied.telGeschaeft" name="telGeschaeft">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-lg-8">
        <label for="email">E-Mail-Adresse</label>
        <input type="text" class="form-control" placeholder="E-Mail-Adresse"
               id="email"
               [(ngModel)]="mitglied.email" name="email">
      </div>
      <div  *ngIf="isAdmin()" class="form-group col-12 col-lg-4">
        <label for="userid">User-ID</label>
        <input type="text" class="form-control" placeholder="User-ID"
               id="userid"
               [(ngModel)]="mitglied.userid" name="userid">
      </div>
    </div>
    <div *ngIf="isAdmin()" class="form-group">
      <input type="checkbox" class="form-check-input ml-0" id="aktivCheck"
             [(ngModel)]="mitglied.isAktiv" name="isAktiv">
      <label for="aktivCheck" class="ml-3">Aktiv</label>
    </div>
    <a class="btn btn-outline-secondary" routerLink="/mitglieder" routerLinkActive="active">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>
