import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SaisonTurnier, SaisonTurnierTyp } from './saison-turnier';
import { StatusService } from '../../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class SaisonTurnierService {

  private urlPrefix = 'api/turnier';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private statusService: StatusService
  ) { }

  getSaisonTurniere(saisonId: number): Observable<SaisonTurnier[]> {
      let url = `${this.urlPrefix}/saison/${saisonId}`
      return this.http.get<SaisonTurnier[]>(url)
        .pipe(
          catchError(this.handleError<SaisonTurnier[]>('getSaisonTurniere'))
        );
  }

  getSaisonTurnier(saisonId: number, turnierId: number): Observable<SaisonTurnier> {
    return this.getSaisonTurniere(saisonId)
      .pipe(
        map(
          saisonTurniere => saisonTurniere.find(saisonTurnier => saisonTurnier.id === turnierId)
        )
      );
  }

  saveSaisonTurnier (saisonTurnier: SaisonTurnier) {
        const url = `${this.urlPrefix}/saison`;
        if (saisonTurnier.id || saisonTurnier.id===0) {
          return this.http.put<SaisonTurnier>(url, saisonTurnier, this.httpOptions)
              .pipe(
                catchError(this.handleError<SaisonTurnier>('updateSaisonTurnier'))
              );
        } else {
          return this.http.post<SaisonTurnier>(url, saisonTurnier, this.httpOptions)
              .pipe(
                catchError(this.handleError<SaisonTurnier>('insertSaisonTurnier'))
              );
        }
  }

    deleteSaisonTurnier(saisonTurnier: SaisonTurnier): Observable<Object> {
          let url = `${this.urlPrefix}/saison?saisonTurnierTyp=${saisonTurnier.saisonTurnierTyp}&saisonTurnierId=${saisonTurnier.id}`;
          return this.http.delete<SaisonTurnier>(url, this.httpOptions)
            .pipe(
              catchError(this.handleError<SaisonTurnier>('deleteSaisonTurnier'))
            );
    }


    private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
      return (error: any): Observable<T> => {

        console.error(error); // log to console instead
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    private log(message: string) {
      this.statusService.add(message);
    }
}
