<div class="termine container">
  <h2>Termine</h2>
  <div class="d-md-none">
    <table class="table table-sm">
      <thead>
      <tr>
        <th>Datum/Ort/Termin</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let termin of terminList">
        <td>
          <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.MM.yyyy'}}, {{termin.zeit}}</div>
          <div class="row m-0 text-muted">{{termin.ort}}</div>
          <div class="row m-0">{{termin.anlass}}</div>
          <div *ngIf="termin.alert">
            <app-information-alert [information]="termin.alert" small="true"></app-information-alert>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-none d-md-block">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-4 col-md-3">Datum/Ort</th>
        <th class="col-8 col-md-9">Termin</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let termin of terminList" class="d-flex">
        <td class="col-4 col-md-3">
          <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.M.yyyy'}}, {{termin.zeit}}</div>
          <div class="row m-0 text-muted">{{termin.ort}}</div>
        </td>
        <td class="col-8 col-md-9">
          <div class="row m-0">{{termin.anlass}}</div>
          <div class="row m-0 text-muted">{{termin.bemerkung}}</div>
          <div *ngIf="termin.alert">
            <app-information-alert [information]="termin.alert" [small]="true"></app-information-alert>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
