<div class="container">
  <h3>Saison-Liste</h3>
  <table class="table table-sm">
    <thead>
      <tr class="d-flex">
        <th class="col-3">Saison</th>
        <th class="col-sm-3 d-none d-sm-table-cell">Von</th>
        <th class="col-sm-3 d-none d-sm-table-cell">Bis</th>
        <th class="col-4 d-sm-none">Von-Bis</th>
        <th class="col-5 col-sm-3"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let saison of saisonList" class="d-flex">
        <td class="col-3">{{saison.startDatum | date: 'yyyy'}} / {{saison.endDatum | date: 'yyyy'}}</td>
        <td class="col-sm-3 d-none d-sm-table-cell">{{saison.startDatum | date: 'dd.MM.yyyy'}}</td>
        <td class="col-sm-3 d-none d-sm-table-cell">{{saison.endDatum | date: 'dd.MM.yyyy'}}</td>
        <td class="col-4 d-sm-none">{{saison.startDatum | date: 'dd.MM.yyyy'}} - {{saison.endDatum | date: 'dd.MM.yyyy'}}</td>
        <td class="col-5 col-sm-3">
          <button class="btn btn-sm btn-danger px-1 py-0 ml-1 float-right" title="Löschen" [disabled]="!canBeDeleted(saison)" (click)="deleteSaison(saison.id)">
            <fa-icon [icon]="faTrash" size="s"></fa-icon>
          </button>
          <a class="btn btn-sm btn-secondary px-1 py-0 ml-1 float-right"
             title="Editieren"
             [routerLink]="['/saisons', saison.id]"
             routerLinkActive="active">
            <fa-icon [icon]="faEdit" size="s"></fa-icon>
          </a>
          <a class="btn btn-sm btn-secondary px-1 py-0 ml-1 float-right" title="Turniere" [routerLink]="['/saisons', saison.id, 'turniere']"><fa-icon [icon]="faThList" size="s"></fa-icon></a>
          <a class="btn btn-sm btn-secondary px-1 py-0 ml-1 float-right" title="Termine" [routerLink]="['/saisons', saison.id, 'termine']"><fa-icon [icon]="faCalendarAlt" size="s"></fa-icon></a>
        </td>
      </tr>
    </tbody>
  </table>
  <a class="btn btn-primary"
     [routerLink]="['/saisons', 'new']"
     [state]="{}"
     routerLinkActive="active">
    <fa-icon [icon]="faPlus" size="s"></fa-icon>
  </a>
</div>
