import { Component, OnInit } from '@angular/core';
import { Mitglied, Vorstandsrolle } from '../mitglied/mitglied';
import { MitgliedService } from '../mitglied/mitglied.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  kontakte: Mitglied[];

  constructor(private mitgliedService: MitgliedService) { }

  ngOnInit(): void {
    this.mitgliedService.getMitgliedList$().subscribe(
      mitgliedList => this.extractKontakte(mitgliedList)
    );
  }

  private extractKontakte(mitgliedList: Mitglied[]) {
    this.kontakte = mitgliedList
      .filter(mitglied => mitglied.showContactDetails);
  }

  phone(mitglied: Mitglied): string {
    return (mitglied.telMobil)?
      mitglied.telMobil:
      (mitglied.telPrivat)?
        mitglied.telPrivat:
        mitglied.telGeschaeft;
  }

}
