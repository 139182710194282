export class SaisonTurnier {

  constructor (saisonId: number, saisonTurnierTyp: SaisonTurnierTyp, id: number, bezeichnung: String, typ: String, modus: String) {
    this.saisonId = saisonId;
    this.saisonTurnierTyp = saisonTurnierTyp;
    this.id = id;
    this.bezeichnung = bezeichnung;
    this.typ = typ;
    this.modus = modus;
  }

  saisonId: number;
  saisonTurnierTyp: SaisonTurnierTyp;
  id: number;
  bezeichnung: String;
  typ: String;
  modus: String;
  canBeDeleted: boolean;
}

export interface EinzelTurnierSerie {
  id: number;
  bezeichnung: String;
  turnierSerieTyp: String;
}

export interface RundenTurnier {
  id: number;
  bezeichnung: String;
  turnierTyp: String;
  turnierModus: String;
}

export interface MultiDisziplinenCup {
  id: number;
  bezeichnung: String;
}

export enum SaisonTurnierTyp {
  RUNDEN_TURNIER = 'RUNDEN_TURNIER',
  EINZEL_TURNIER_SERIE = 'EINZEL_TURNIER_SERIE',
  MULTI_DISZIPLINEN_CUP = 'MULTI_DISZIPLINEN_CUP'
}

export enum RundenTurnierTyp {
  HERBST_TURNIER = 'HERBST_TURNIER',
  KLUBMEISTERSCHAFT = 'KLUBMEISTERSCHAFT'
}

export enum RundenTurnierModus {
  SCHWEIZER_SYSTEM = 'SCHWEIZER_SYSTEM',
  VOLLRUNDIG = 'VOLLRUNDIG'
}
