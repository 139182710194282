<div class="rundenturnierDetail container" *ngIf="rundenturnier">
  <h3>{{rundenturnier.bezeichnung}} <span class="d-none d-sm-inline">({{saison.bezeichnung}})</span></h3>
  <div  class="py-1" *ngFor="let turnierrunde of rundenturnier.turnierrunden">
    <app-turnierrunde [turnierrunde]="turnierrunde"></app-turnierrunde>
  </div>
  <app-rundenturnier-rangliste [rundenturnier]="rundenturnier"></app-rundenturnier-rangliste>
  <a class="btn btn-secondary btn-sm mt-2"
     title="Zurück"
     (click)="back()">
    <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
  </a>
</div>
