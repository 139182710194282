<div class="informationEdit container">
  <h2>Mitteilung</h2>
  <form (ngSubmit)="onSubmit()" #informationForm="ngForm">
    <div class="form-group">
      <label for="messageText">Text</label>
      <textarea type="text" class="form-control" rows="3"
                id="messageText"
                placeholder="Mitteilungstext"
                [(ngModel)]="information.message" name="message">
      </textarea>
    </div>
    <div class="form-group">
      <label for="styleSelect">Style</label>
      <select class="form-control" id="styleSelect"
              [(ngModel)]="information.style" name="style">
        <option class="alert alert-primary">primary</option>
        <option class="alert alert-secondary">secondary</option>
        <option class="alert alert-success">success</option>
        <option class="alert alert-danger">danger</option>
        <option class="alert alert-warning">warning</option>
        <option class="alert alert-info">info</option>
        <option class="alert alert-light">light</option>
        <option class="alert alert-dark">dark</option>
      </select>
    </div>
    <div class="form-group">
      <input type="checkbox" class="form-check-input ml-0" id="boldCheck"
             [(ngModel)]="information.displayBold" name="displayBold">
      <label for="boldCheck" class="ml-3">Fett darstellen</label>
    </div>
    <div class="form-group">
      <label for="attachment">Anhang</label>
      <select id="attachment" class="form-control"
              [compareWith]="compareById"
              [(ngModel)]="information.attachment" name="attachment">
        <option *ngFor="let dokument of dokumentList"
                [ngValue]="dokument">
          <span *ngIf="dokument">[{{dokument.filename}}]: {{dokument.description}}</span>
          <span *ngIf="!dokument">(kein Anhang)</span>
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="displayDuration">Datumsbereich für Anzeige</label>
      <div id="displayDuration">
<!--
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-secondary">von</button>
          </div>
          <input class="form-control" placeholder="dd.mm.yyyy"
                 [(ngModel)]="information.displayFrom" name="displayFrom"
                 ngbDatepicker #df="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="df.toggle()" type="button"></button>
          </div>
        </div>
-->
        <input type="date" class="form-control" required
                     id="displayFrom"
                     [(ngModel)]="information.displayFrom" name="displayFrom">
        <input type="date" class="form-control" required
                     id="displayTo"
                     [(ngModel)]="information.displayTo" name="displayTo">
        <!--
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-secondary">bis</button>
          </div>
          <input class="form-control" placeholder="dd.mm.yyyy"
                 [(ngModel)]="information.displayTo" name="displayTo"
                 ngbDatepicker #dt="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="dt.toggle()" type="button"></button>
          </div>
        </div>
-->
      </div>
    </div>
    <div class="form-group">
      <label for="vorschau">Vorschau</label>
      <div id="vorschau">
        <app-information-alert [information]="information"
                               [defaultMessage]="'Lorem ipsum dolor sit amet'"></app-information-alert>
      </div>
    </div>
    <a class="btn btn-outline-secondary" routerLink="/informationen" routerLinkActive="active">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>
