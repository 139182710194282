import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { TurnierSerie, Einzelturnier, EinzelturnierTyp } from '../../turnier-serie/turnier-serie';
import { TurnierSerieService } from '../../turnier-serie/turnier-serie.service';

@Component({
  "selector": 'app-einzelturnier-edit',
  "templateUrl": './einzelturnier-edit.component.html',
  "styleUrls": ['./einzelturnier-edit.component.scss']
})
export class EinzelturnierEditComponent implements OnInit {

  saison: Saison;
  turnierserie: TurnierSerie;
  einzelturnier: Einzelturnier;

  einzelturnierId: number;
  datum: Date;
  einzelturnierTyp: EinzelturnierTyp;
  einzelturnierTypList: EinzelturnierTyp[];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private saisonService: SaisonService,
    private turnierSerieService: TurnierSerieService
  ) { }

  ngOnInit(): void {
      this.route.params
        .subscribe(
          (params) => {
            let saisonId = params['saisonId'];
            let turnierId = params['turnierId'];
            let einzelturnierId = params['einzelturnierId'];
            this.readSaison(+saisonId);
            this.readTurnierSerie(+turnierId, einzelturnierId);
          }
        );

      this.einzelturnierTypList = Object.keys(EinzelturnierTyp).map(key => EinzelturnierTyp[key]);
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readTurnierSerie(turnierId: number, einzelturnierId: number): void {
    this.turnierSerieService.getTurnierSerie(turnierId)
      .subscribe(
        turnierserie => {
          this.turnierserie = turnierserie;

          if (isNaN(einzelturnierId)) {
            this.einzelturnier =
              {
                id: null,
                runde: null,
                einzelturnierTyp: null,
                bezeichnung: null,
                datum: null,
                rangliste: [],
                canBeDeleted: true
              }
              this.einzelturnierId = null;
              this.datum = null;
              this.einzelturnierTyp = null;

          } else {
            this.einzelturnier = turnierserie.einzelturniere.find(einzelturnier => einzelturnier.id==einzelturnierId);
            this.einzelturnierId = this.einzelturnier.id;
            this.datum = this.einzelturnier.datum;
            this.einzelturnierTyp = this.einzelturnier.einzelturnierTyp
          }
        }
      );
  }

  onSubmit() {
    let einzelturnier: Einzelturnier = {
      id: this.einzelturnierId,
      runde: null,
      einzelturnierTyp: this.einzelturnierTyp,
      bezeichnung: null,
      datum: this.datum,
      rangliste: [],
      canBeDeleted: true
    }

    this.turnierSerieService.saveEinzelturnier(this.turnierserie.id, einzelturnier).subscribe({
      next: data => {
        console.log("Save successful");
         this.router.navigate(['saisons', this.saison.id, 'turniere', this.turnierserie.id, 'turnierserie-detail-edit']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
