import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Saison } from './saison';
import { StatusService } from '../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class SaisonService {

  private urlPrefix = '/api/saison';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private statusService: StatusService) { }

  getSaisonList$(): Observable<Saison[]> {
    let url = `${this.urlPrefix}`;
    return this.http.get<Saison[]>(url)
      .pipe(
        catchError(this.handleError<Saison[]>('getSaisonList$', []))
      );
  }

  getSaison(saisonId: number): Observable<Saison> {
      let url = `${this.urlPrefix}/${saisonId}`;
      return this.http.get<Saison>(url)
        .pipe(
          catchError(this.handleError<Saison>('getSaison'))
        );
  }

  getCurrentSaison(): Observable<Saison> {
    let url = `${this.urlPrefix}/current`;
    return this.http.get<Saison>(url)
      .pipe(
        catchError(this.handleError<Saison>('getCurrentSaison', null))
      );
  }

  getLastSaison(): Observable<Saison> {
    let url = `${this.urlPrefix}/last`;
    return this.http.get<Saison>(url)
      .pipe(
        catchError(this.handleError<Saison>('getLastSaison', null))
      );
  }

  saveSaison(saison: Saison): Observable<Saison> {

      let url = `${this.urlPrefix}`;
      if (saison.id || saison.id===0) {
      return this.http.put<Saison>(url, saison, this.httpOptions)
          .pipe(
            catchError(this.handleError<Saison>('updateSaison'))
          );
      } else {
        return this.http.post<Saison>(url, saison, this.httpOptions)
            .pipe(
              catchError(this.handleError<Saison>('insertSaison'))
            );
      }
  }

  deleteSaison(saisonId: number): Observable<Object> {
        let url = `${this.urlPrefix}/${saisonId}`;
        return this.http.delete(url, this.httpOptions)
          .pipe(
            catchError(this.handleError<Saison>('deleteSaison'))
          );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.logError(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private logError(message: string) {
    this.statusService.add(message);
  }
}
