import { Component, Input, OnInit } from '@angular/core';
import { TurnierSerieRangliste } from '../../turnier-serie/turnier-serie';

@Component({
  selector: 'app-multidisziplinen-cup-rangliste',
  templateUrl: './multidisziplinen-cup-rangliste.component.html',
  styleUrls: ['./multidisziplinen-cup-rangliste.component.scss']
})
export class MultidisziplinenCupRanglisteComponent implements OnInit {

  @Input() rangliste: TurnierSerieRangliste;

  constructor() { }

  ngOnInit(): void {
  }

  public runden(): number[] {
    let runden: number[] = [];
    for (let index= 1; index <= this.rangliste.anzahlTurniere; index += 1) {
      runden.push(index);
    }
    return runden;
  }

  public anzahlResultateText(): String {
    const gewerteteResultate = this.rangliste.anzahlGewerteteResultate===1?
      `1 gewertetes Resultat`:
      `${this.rangliste.anzahlGewerteteResultate} gewertete Resultate`;
    const streichResultate = this.rangliste.anzahlStreichresultate===1?
      `1 Streichresultat`:
      `${this.rangliste.anzahlStreichresultate} Streichresultate`;

    return `${gewerteteResultate}, ${streichResultate}`;
  }
}
