import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash, faThList, faListOl, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { SaisonTurnierService } from '../saison-turnier/saison-turnier.service';
import { SaisonTurnier, SaisonTurnierTyp } from '../saison-turnier/saison-turnier';
import { StatusService } from '../../status/status.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-turnier-list',
    templateUrl: './turnier-list.component.html',
    styleUrls: ['./turnier-list.component.scss']
})
export class TurnierListComponent implements OnInit {

    saisonList: Saison[];
    selectedSaison: Saison;

    turnierList: SaisonTurnier[];

    constructor(
        private route: ActivatedRoute,
        private saisonService: SaisonService,
        private saisonTurnierService: SaisonTurnierService,
        private statusService: StatusService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.route.params
            .subscribe(
                (params: Params) => {
                    let saisonId = params['saisonId'];
                    if (isNaN(saisonId)) {
                        this.readCurrentSaison();
                    } else {
                        this.readSaison(+saisonId);
                    }
                }
            );


        this.readSaisonList();
    }


    isAdmin(): boolean {
      return this.authService.isAdmin;
    }

    readSaisonList(): void {
      this.saisonService.getSaisonList$()
        .subscribe(saisonList => {
          this.saisonList = saisonList;
        });
    }

    readSaison(saisonId: number): void {
      this.saisonService.getSaison(saisonId)
        .subscribe(saison => {
          this.selectedSaison = saison;
          this.readSaisonTurniere();
        });
    }

    readCurrentSaison(): void {
        this.saisonService.getCurrentSaison()
            .subscribe(currentSaison => {
                this.selectedSaison = currentSaison;
                this.readSaisonTurniere();
            });
    }

    readSaisonTurniere(): void {
      this.saisonTurnierService.getSaisonTurniere(this.selectedSaison.id)
        .subscribe(saisonTurniere => {
          this.turnierList = saisonTurniere;
        });
    }

  onSaisonChange(): void {
    this.readSaisonTurniere();
  }

    compareById(saison1: Saison, saison2: Saison) {
        if (saison1 && saison2) {
            return saison1.id == saison2.id;
        } else {
            return false;
        }
    }

  canBeDeleted(saisonTurnier: SaisonTurnier) {
    return saisonTurnier.canBeDeleted;
  }

  deleteTurnier(saisonTurnier: SaisonTurnier) {
    this.saisonTurnierService.deleteSaisonTurnier(saisonTurnier)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.readSaisonTurniere();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      });
   }

  detailDiskriminator(saisonTurnier: SaisonTurnier): String {
    switch(saisonTurnier.saisonTurnierTyp) {
      case 'RUNDEN_TURNIER':
        return 'rundenturnier-detail';

      case 'EINZEL_TURNIER_SERIE':
      case 'MULTI_DISZIPLINEN_CUP':
        return 'turnierserie-detail';
    }
  }

  detailEditDiskriminator(saisonTurnier: SaisonTurnier): String {
    switch(saisonTurnier.saisonTurnierTyp) {
      case 'RUNDEN_TURNIER':
        return 'rundenturnier-detail-edit';

      case 'EINZEL_TURNIER_SERIE':
      case 'MULTI_DISZIPLINEN_CUP':
        return 'turnierserie-detail-edit';
    }
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faThList = faThList;
  faListOl = faListOl;
  faArrowCircleLeft = faArrowCircleLeft;
}
