<div class="turnierpaarungEdit container" *ngIf="saison && rundenturnier && turnierrunde && mitgliedList">
  <h3>
    Paarung:
    <span *ngIf="weissPersonId && schwarzPersonId">{{name(weissPersonId)}} - {{name(schwarzPersonId)}}</span>
  </h3>
  <form (ngSubmit)="onSubmit()" #terminForm="ngForm">
    <div class="form-group">
      <label for="spielerWeiss">Weiss</label>
      <select id="spielerWeiss" class="form-control"
              [(ngModel)]="weissPersonId" name="weissPersonId">
        <option *ngFor="let mitglied of mitgliedList"
                [ngValue]="mitglied.id">
          <span>{{mitglied.id}}: {{mitglied.vorname}} {{mitglied.name}}</span>
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="spielerSchwarz">Schwarz</label>
      <select id="spielerSchwarz" class="form-control"
              [(ngModel)]="schwarzPersonId" name="schwarzPersonId">
        <option *ngFor="let mitglied of mitgliedList"
                [ngValue]="mitglied.id">
          <span>{{mitglied.id}}: {{mitglied.vorname}} {{mitglied.name}}</span>
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="paarungsresultat">Resultat</label>
      <select id="paarungsresultat" class="form-control"
              [(ngModel)]="resultat" name="resulat">
        <option *ngFor="let paarungsresultat of paarungsResultatList"
                [ngValue]="paarungsresultat">
          <span>{{paarungsresultat}}</span>
        </option>
      </select>
    </div>
    <button class="btn btn-outline-secondary" (click)="back()">Abbrechen</button>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>

</div>
