<div class="container">
  <h2>Junioren-Schachkurs</h2>
  <div class="container row">
    {{getDescription()}}
  </div>
  <div class="container row pt-2">
    {{getModus()}}
  </div>
  <div *ngIf="kursleiter" class="d-none d-md-block">
    <div class="row mt-3">
      <div class="col-2"><strong>Ort</strong></div>
      <div class="col-10"><a routerLink="/lokal">Bastelraum</a> im Begegnungszentrum Düdingen <small>(Haupteingang,
        rechte Seitentüre)</small></div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Zeit</strong></div>
      <div class="col-10"><strong>Dienstag, 18:30h - 19:15h</strong></div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Kursperiode</strong></div>
      <div class="col-10">
        <div class="row m-0">
          {{getKursperiode()}}
        </div>
        <div class="row m-0">
          Siehe <a class="px-1" href="https://www.duedingen.ch/ferienplan" target="_blank">Ferienplan</a> der Gemeinde
          Düdingen
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Anmeldung</strong></div>
      <div class="col-10">
        <div class="row m-0">
          {{getAnmeldung()}}
        </div>
        <div class="row m-0">
          {{getAnmeldung2()}}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Teilnehmer</strong></div>
      <div class="col-10">{{getTeilnehmer()}}</div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Kosten</strong></div>
      <div class="col-10">{{getKosten()}}</div>
    </div>
    <div class="row mt-2">
      <div class="col-2"><strong>Kursleiter</strong></div>
      <div *ngFor="let leiter of kursleiter" class="col-3">
        <div class="row m-0">
          <strong>{{leiter.vorname}} {{leiter.name}}</strong>
        </div>
        <div class="row m-0">
          {{leiter.email}}
        </div>
        <div class="row m-0">
          {{phone(leiter)}}
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="juniorenTermine?.length>0">
      <div class="col-2"><strong>Nächste Termine</strong></div>
      <div class="col-10">
        <div *ngFor="let termin of juniorenTermine | slice:0:4">
          <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.MM.yyyy'}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="kursleiter" class="d-md-none">
    <div class="row mt-3">
      <div class="col-3"><strong>Ort</strong></div>
      <div class="col-9">
        <div class="ml-1">
          <a routerLink="/lokal">Bastelraum</a> im Begegnungszentrum Düdingen <small>(Haupteingang, rechte Seitentüre)</small>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Zeit</strong></div>
      <div class="col-9">
        <div class="ml-1">
          <strong>Dienstag, 18:30h - 19:15h</strong>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Kursperiode</strong></div>
      <div class="col-9">
        <div class="row ml-1">
          {{getKursperiode()}}
        </div>
        <div class="row ml-1">
          Siehe <a class="px-1" href="https://www.duedingen.ch/ferienplan" target="_blank">Ferienplan</a> der Gemeinde
          Düdingen
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Anmeldung</strong></div>
      <div class="col-9">
        <div class="row ml-1">
          {{getAnmeldung()}}
        </div>
        <div class="row ml-1">
          {{getAnmeldung2()}}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Teilnehmer</strong></div>
      <div class="col-9">
        <div class="ml-1">
          {{getTeilnehmer()}}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Kosten</strong></div>
      <div class="col-9">
        <div class="ml-1">
            {{getKosten()}}
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3"><strong>Kursleiter</strong></div>
      <div class="col-9">
        <div class="ml-1" *ngFor="let leiter of kursleiter">
          <div class="row m-0">
            <strong>{{leiter.vorname}} {{leiter.name}}</strong>
          </div>
          <div class="row m-0">
            {{leiter.email}}
          </div>
          <div class="row m-0 mb-3">
            {{phone(leiter)}}
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="juniorenTermine?.length>0">
      <div class="col-3"><strong>Nächste Termine</strong></div>
      <div class="col-9">
        <div class="ml-1" *ngFor="let termin of juniorenTermine | slice:0:4">
          <div class="row m-0">{{termin.wochentag}}, {{termin.datum | date: 'dd.MM.yyyy'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
