<h4>Rangliste</h4>
<table class="table table-responsive table-sm table-bordered table-hover">
  <thead>
  <tr class="thead-dark">
    <th>Rang</th>
    <th>Spieler</th>
    <th *ngFor="let runde of runden()">{{runde}}</th>
    <th>Total</th>
    <th>Gewertete Punkte</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let rangierung of rangliste.rangierungen">
    <td>{{rangierung.rang}}</td>
    <td>{{rangierung.vorname}} {{rangierung.name}}</td>
    <td *ngFor="let einzelresultat of rangierung.einzelturnierResultate" [ngClass]="{'text-muted': einzelresultat.isStreichresultat, 'font-italic': einzelresultat.isStreichresultat}">{{einzelresultat.punkte}}</td>
    <td class="table-secondary">{{rangierung.punkteSumme}}</td>
    <td class="table-primary font-weight-bold">{{rangierung.punkteGewertet}}</td>
  </tr>
  </tbody>
</table>
<div class="font-italic text-muted">{{anzahlResultateText()}}</div>
