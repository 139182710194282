import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { faUser, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private authService: AuthService, private keycloak: KeycloakService) { }

  ngOnInit(): void {
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  getProfile(): KeycloakProfile {
    return this.authService.getProfile();
  }

  faUser = faUser;
  faArrowCircleLeft = faArrowCircleLeft;
}
