<div class="saisonEdit container">
  <h2>Saison: {{saison.startDatum | date: 'yyyy'}}/{{saison.endDatum | date: 'yyyy'}}</h2>
  <form (ngSubmit)="onSubmit()" #saisonForm="ngForm">
    <div class="form-group">
      <label for="startDatum">Start-Datum</label>
        <input type="date" class="form-control" required
               id="startDatum"
               [(ngModel)]="saison.startDatum" name="startDatum">
    </div>
    <div class="form-group">
      <label for="endDatum">End-Datum</label>
        <input type="date" class="form-control" required
               id="endDatum"
               [(ngModel)]="saison.endDatum" name="endDatum">
    </div>
    <a class="btn btn-outline-secondary" routerLink="/saisons" routerLinkActive="active">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>
