import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/authguard';

import { StartComponent } from './start/start.component';
import { InformationListComponent } from './information/information-list/information-list.component';
import { InformationEditComponent } from './information/information-edit/information-edit.component';
import { TurnierListComponent } from './turnier/turnier-list/turnier-list.component';
import { TurnierEditComponent } from './turnier/turnier-edit/turnier-edit.component';
import { SaisonListComponent } from './saison/saison-list/saison-list.component';
import { SaisonEditComponent } from './saison/saison-edit/saison-edit.component';
import { RundenTurnierDetailComponent } from './turnier/runden-turnier-detail/runden-turnier-detail.component';
import { RundenTurnierDetailEditComponent } from './turnier/runden-turnier-detail-edit/runden-turnier-detail-edit.component';
import { TurnierRundeEditComponent } from './turnier/runden-turnier-detail-edit/turnier-runde-edit/turnier-runde-edit.component';
import { TurnierRundeDetailEditComponent } from './turnier/runden-turnier-detail-edit/turnier-runde-detail-edit/turnier-runde-detail-edit.component';
import { TurnierPaarungEditComponent } from './turnier/runden-turnier-detail-edit/turnier-paarung-edit/turnier-paarung-edit.component';
import { TurnierSerieDetailComponent } from './turnier/turnier-serie-detail/turnier-serie-detail.component';
import { TurnierSerieDetailEditComponent } from './turnier/turnier-serie-detail-edit/turnier-serie-detail-edit.component';
import { EinzelturnierDetailEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-detail-edit/einzelturnier-detail-edit.component';
import { EinzelturnierEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-edit/einzelturnier-edit.component';
import { EinzelturnierRangierungEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-rangierung-edit/einzelturnier-rangierung-edit.component';
import { TerminListComponent } from './termin/termin-list/termin-list.component';
import { TerminEditComponent } from './termin/termin-edit/termin-edit.component';
import { MitgliedListComponent } from './mitglied/mitglied-list/mitglied-list.component';
import { MitgliedEditComponent } from './mitglied/mitglied-edit/mitglied-edit.component';
import { MitgliedEloListComponent } from './mitglied/mitglied-elo-list/mitglied-elo-list.component';
import { ResultateComponent } from './resultate/resultate.component';
import { VorstandComponent } from './vorstand/vorstand.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { JuniorenComponent } from './junioren/junioren.component';
import { DokumentListComponent } from './dokument/dokument-list/dokument-list.component';
import { DokumentEditComponent } from './dokument/dokument-edit/dokument-edit.component';
import { LokalComponent } from './lokal/lokal.component';

const routes: Routes = [
  { path: '', component: StartComponent },

  /** Paths for start screen */
  { path: 'start', component: StartComponent },
  { path: 'saisons/:saisonId/turniere/:turnierId/rundenturnier-detail', component: RundenTurnierDetailComponent },
  { path: 'saisons/:saisonId/turniere/:turnierId/turnierserie-detail', component: TurnierSerieDetailComponent },

  /** Public paths */
  { path: 'resultate', component: ResultateComponent },
  { path: 'vorstand', component: VorstandComponent },
  { path: 'kontakt', component: KontaktComponent },
  { path: 'junioren', component: JuniorenComponent },
  { path: 'mitglieder', component: MitgliedListComponent },
  { path: 'lokal', component: LokalComponent },
  { path: 'saisons/:saisonId/termine', component: TerminListComponent },

  /** Paths for members */
  { path: 'saisons/:saisonId/turniere/:turnierId/turnierrunde/:turnierrundeId/paarung/:paarungId', component: TurnierPaarungEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin', 'ROLE_Member' ] }  },
  { path: 'dokumente', component: DokumentListComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin', 'ROLE_Member'] }  },
  { path: 'mitglieder/:mitgliedId', component: MitgliedEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin', 'ROLE_Member'] }   },
  { path: 'mitglieder/:mitgliedId/elo', component: MitgliedEloListComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin', 'ROLE_Member'] }   },

  /** Paths for admin */
  { path: 'informationen', component: InformationListComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] } },
  { path: 'informationen/:informationId', component: InformationEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons', component: SaisonListComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons/:saisonId', component: SaisonEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] } },
  { path: 'saisons/:saisonId/termine/:terminId', component: TerminEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] } },
  { path: 'saisons/:saisonId/turniere', component: TurnierListComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] } },
  { path: 'saisons/:saisonId/turniere/:turnierId', component: TurnierEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] } },
  { path: 'saisons/:saisonId/turniere/:turnierId/rundenturnier-detail-edit', component: RundenTurnierDetailEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons/:saisonId/turniere/:turnierId/turnierserie-detail-edit', component: TurnierSerieDetailEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons/:saisonId/turniere/:turnierId/turnierrunde/:turnierrundeId', component: TurnierRundeEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons/:saisonId/turniere/:turnierId/turnierrunde-detail-edit/:turnierrundeId', component: TurnierRundeDetailEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  },
  { path: 'saisons/:saisonId/turniere/:turnierId/einzelturnier-edit/:einzelturnierId', component: EinzelturnierEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }   },
  { path: 'saisons/:saisonId/turniere/:turnierId/einzelturnier-detail-edit/:einzelturnierId', component: EinzelturnierDetailEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }   },
  { path: 'saisons/:saisonId/turniere/:turnierId/einzelturnier-detail-edit/:einzelturnierId/rangierung-edit/:rangierungId', component: EinzelturnierRangierungEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }   },
  { path: 'dokumente/:dokumentId', component: DokumentEditComponent, canActivate: [AuthGuard], data: { roles: ['ROLE_Admin'] }  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
