<div class="turnierEdit container">
  <h2>Turnier</h2>
  <form (ngSubmit)="onSubmit()" #terminForm="ngForm">
    <div class="form-group">
      <label for="saisonTurnierTypSelect">Saison-Turnier-Typ</label>
      <select id="saisonTurnierTypSelect" class="form-control"
              [(ngModel)]="saisonTurnier.saisonTurnierTyp" name="saisonTurnierTyp">
        <option *ngFor="let saisonTurnierTyp of saisonTurnierTypList"
                [ngValue]="saisonTurnierTyp">{{saisonTurnierTyp}}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="bezeichnung">Bezeichnung</label>
      <input type="text" class="form-control" required
             id="bezeichnung"
             [(ngModel)]="saisonTurnier.bezeichnung" name="bezeichnung">
    </div>


    <div class="form-group">
      <label for="turnierTypSelect">Typ</label>
      <select id="turnierTypSelect" class="form-control"
              [(ngModel)]="saisonTurnier.typ" name="typ">
        <option *ngFor="let turnierTyp of rundenTurnierTypList"
                [ngValue]="turnierTyp">{{turnierTyp}}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="turnierModusSelect">Modus</label>
      <select id="turnierModusSelect" class="form-control"
              [(ngModel)]="saisonTurnier.modus" name="modus">
        <option *ngFor="let turnierModus of rundenTurnierModusList"
                [ngValue]="turnierModus">{{turnierModus}}</option>
      </select>
    </div>

    <a class="btn btn-outline-secondary" [routerLink]="['/saisons', saisonId, 'turniere']">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>

