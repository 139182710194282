import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { TurnierSerie, TurnierSerieTyp } from '../turnier-serie/turnier-serie';
import { TurnierSerieService } from '../turnier-serie/turnier-serie.service';

@Component({
  selector: 'app-turnier-serie-detail',
  templateUrl: './turnier-serie-detail.component.html',
  styleUrls: ['./turnier-serie-detail.component.scss']
})
export class TurnierSerieDetailComponent implements OnInit {

  saison: Saison;
  turnierSerie: TurnierSerie;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private saisonService: SaisonService,
    private turnierSerieService: TurnierSerieService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          this.readSaison(+saisonId);
          this.readTurnierSerie(+turnierId);
        }
      );
  }

  readTurnierSerie(turnierId: number): void {
    this.turnierSerieService.getTurnierSerie(turnierId)
      .subscribe(
        turnierSerie => {
          this.turnierSerie = turnierSerie;
        }
      );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  public isKurzzeitTurnierSerie() {
    return this.turnierSerie.typ===TurnierSerieTyp.KURZZEIT_TURNIER_SERIE;
  }

  public isMultidisziplinenCup() {
    return this.turnierSerie.typ===TurnierSerieTyp.MULTI_DISZIPLINEN_CUP;
  }

  back(): void {
      this.location.back();
  }

  faArrowCircleLeft = faArrowCircleLeft;
}
