import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saison',
  templateUrl: './saison.component.html',
  styleUrls: ['./saison.component.scss']
})
export class SaisonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
