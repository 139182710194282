import { Component, Input, AfterViewInit, OnChanges } from '@angular/core';
import { Weg, Punkt } from './kartenlayer';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';
import 'leaflet-routing-machine';
import 'lrm-graphhopper';

@Component({
  selector: 'app-karte',
  templateUrl: './karte.component.html',
  styleUrls: ['./karte.component.scss']
})
export class KarteComponent implements AfterViewInit {

  @Input() ziel: Punkt;
  @Input() wege: Weg[];

  private ziellayer: L.FeatureGroup;
  private routeControls: Map<String, L.Routing.Control>;
  private routeBounds: Map<String, L.Bounds>;

  constructor() { }

  private map;
  private viewInitialized: boolean = false;

  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  ngOnChanges(changes): void {
    if (this.ready()) {
      this.initMap();
    }
  }

  private ready(): boolean {
    return this.ziel && this.wege && this.viewInitialized;
  }

  private initMap(): void {
    // https://stackoverflow.com/questions/41144319/leaflet-marker-not-found-production-env
    const iconRetinaUrl = 'assets/marker-icon-2x.png';
    const iconUrl = 'assets/marker-icon.png';
    const shadowUrl = 'assets/marker-shadow.png';
    const iconDefault = icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    Marker.prototype.options.icon = iconDefault;

    var coordinates = [this.ziel.latitude, this.ziel.longitude];

    this.map = L.map('karte', {
          center: coordinates,
          zoom: 18
        });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      center: coordinates,
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    let ziel = L.marker(coordinates).bindPopup(this.ziel.popupHtml);
    this.ziellayer = L.featureGroup([ziel]).addTo(this.map);

    if (this.wege) {
      this.routeControls = new Map<String, L.Routing.Control>();
      this.routeBounds = new Map<String, L.Bounds>();
      this.wege.forEach(
        weg => {
          let popupHtmls = [weg.start.popupHtml, this.ziel.popupHtml];
          let routeControl: L.Routing.Control = L.Routing.control({
            waypoints: [
              L.latLng(weg.start.latitude, weg.start.longitude),
              L.latLng(this.ziel.latitude, this.ziel.longitude)
            ],
            show: false,
            router: new L.Routing.graphHopper('7678eb22-e7cd-46fc-9a8e-e6f8f579cf3e', {
              urlParameters: { vehicle: weg.vehicle }
            }),
            lineOptions: {
              addWaypoints: false
            },
            createMarker: (waypointIndex, waypoint, numberOfWaypoints) => L.marker(waypoint.latLng).bindPopup(popupHtmls[waypointIndex])
          });
          routeControl.on('routesfound', e => {
            let line = L.Routing.line(e.routes[0]);
            this.routeBounds.set(weg.label, line.getBounds());
          });
          routeControl.route();
          this.routeControls.set(weg.label, routeControl);
        }
      )
    }
  }

  public displayZiel() {
     this.routeControls.forEach((routeControl, label) => this.map.removeControl(routeControl));
     this.ziellayer.addTo(this.map);
     this.map.fitBounds(this.ziellayer.getBounds(), {padding: [20, 20]});
  }

  public displayWeg(weg: Weg) {
     this.map.removeLayer(this.ziellayer);
     this.routeControls.forEach((routeControl, label) => this.map.removeControl(routeControl));
     const routeControl: L.Routing.Control = this.routeControls.get(weg.label);
     const bounds = this.routeBounds.get(weg.label);

     routeControl.addTo(this.map);
     console.log('bounds', bounds);

     this.map.fitBounds(bounds, {padding: [20, 20]});
  }
}
