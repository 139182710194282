import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SaisonTurnierService } from '../saison-turnier/saison-turnier.service';
import { SaisonTurnier, SaisonTurnierTyp, RundenTurnierTyp, RundenTurnierModus } from '../saison-turnier/saison-turnier';


@Component({
  selector: 'app-turnier-edit',
  templateUrl: './turnier-edit.component.html',
  styleUrls: ['./turnier-edit.component.scss']
})
export class TurnierEditComponent implements OnInit {

  saisonId: number;
  turnierId: number;
  saisonTurnierTypList: SaisonTurnierTyp[]
  rundenTurnierTypList: RundenTurnierTyp[]
  rundenTurnierModusList: RundenTurnierModus[]
  saisonTurnier: SaisonTurnier;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private saisonTurnierService: SaisonTurnierService
  ) {
    this.saisonTurnierTypList = Object.values(SaisonTurnierTyp);
    this.rundenTurnierTypList = Object.values(RundenTurnierTyp);
    this.rundenTurnierModusList = Object.values(RundenTurnierModus);
  }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
            this.saisonId = +params['saisonId'];
            const turnierId = params['turnierId'];
            if (isNaN(turnierId)) {
              this.newTurnier();
            } else {
              this.readTurnier(this.saisonId, +turnierId);
            }
        }
      );
  }

  private readTurnier(saisonId: number, turnierId: number) {
      this.saisonTurnierService.getSaisonTurnier(saisonId, turnierId)
        .subscribe((saisonTurnier) => this.saisonTurnier = saisonTurnier);
  }

    private newTurnier() {
        this.saisonTurnier = {
          saisonId: this.saisonId,
          saisonTurnierTyp: null,
          id: null,
          bezeichnung: '',
          typ: '',
          modus: '',
          canBeDeleted: true
        }
    }


  onSubmit() {
    this.saisonTurnierService.saveSaisonTurnier(this.saisonTurnier).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['saisons', this.saisonId, 'turniere']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
