<div *ngIf="!displaySmall()" class="alert alert-{{information.style}}" [ngClass]="{'font-weight-bold': information.displayBold}">
  <span *ngIf="information.message">{{information.message}}</span>
  <span *ngIf="!information.message && defaultMessage">{{defaultMessage}}</span>
  <span *ngIf="information.attachment">
    <span class="d-none d-lg-inline float-right">{{information.attachment?.filename}}</span>
    <button class="btn btn-info py-0 alert alert-{{information.style}} border-0 float-right">
          <fa-icon [icon]="faPaperclip" size="s" (click)="downloadDokument(information.attachment)"></fa-icon>
    </button>
  </span>
</div>
<p *ngIf="displaySmall()" class="alert alert-{{information.style}} p-0 m-0">{{information.message}}</p>
