<div class="einzelturnierRangierungEdit container" *ngIf="saison && turnierserie && einzelturnier && mitgliedList">
  <h3>
    Rangierung:
    <span *ngIf="personId">{{name(personId)}}</span>
  </h3>
  <form (ngSubmit)="onSubmit()" #terminForm="ngForm">
    <div class="form-group">
      <label for="spieler">Spieler</label>
      <select id="spieler" class="form-control"
              [(ngModel)]="personId" name="personId">
        <option *ngFor="let mitglied of mitgliedList"
                [ngValue]="mitglied.id">
          <span>{{mitglied.id}}: {{mitglied.vorname}} {{mitglied.name}}</span>
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="resultat">Resultat</label>
      <input type="text" class="form-control" placeholder="Resultat"
             id="resultat"
             [(ngModel)]="resultat" name="resultat">
    </div>

    <a class="btn btn-outline-secondary" [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-detail-edit', einzelturnier.id]">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>

</div>
