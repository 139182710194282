<div class="turnierRundeEdit container" *ngIf="saison && rundenturnier && turnierrunde">
  <h3 class="my-4">
    <span class="d-none d-md-inline">{{rundenturnier.bezeichnung}} ({{saison.bezeichnung}}) - </span>
    <span>Runde XY {{turnierrunde.runde}}</span>
    <span class="d-none d-sm-inline"> ({{turnierrunde.datum | date: 'dd.MM.yyyy'}})</span>
  </h3>
  <form (ngSubmit)="onSubmit()" #terminForm="ngForm">

    <div class="form-group">
      <label for="datum">Datum</label>
      <input type="date" class="form-control" required
             id="datum"
             [(ngModel)]="turnierrunde.datum" name="datum">
    </div>

    <a class="btn btn-outline-secondary" [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'rundenturnier-detail-edit']">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>

