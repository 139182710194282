import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { TurnierSerie, Einzelturnier, EinzelturnierRangierung } from '../../turnier-serie/turnier-serie';
import { TurnierSerieService } from '../../turnier-serie/turnier-serie.service';
import { MitgliedService } from '../../../mitglied/mitglied.service';
import { Mitglied } from '../../../mitglied/mitglied';
import { StatusService } from '../../../status/status.service';

@Component({
  selector: 'app-einzelturnier-rangierung-edit',
  templateUrl: './einzelturnier-rangierung-edit.component.html',
  styleUrls: ['./einzelturnier-rangierung-edit.component.scss']
})
export class EinzelturnierRangierungEditComponent implements OnInit {

  saison: Saison;
  turnierserie: TurnierSerie;
  einzelturnier: Einzelturnier;
  mitgliedList: Mitglied[];

  rangierungId: number;
  personId: number;
  personVorname: string;
  personName: string;
  resultat: number;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private saisonService: SaisonService,
      private mitgliedService: MitgliedService,
      private turnierserieService: TurnierSerieService,
      private statusService: StatusService
  ) { }

  ngOnInit(): void {
      this.route.params
        .subscribe(
          (params) => {
            let saisonId = params['saisonId'];
            let turnierId = params['turnierId'];
            let einzelturnierId = params['einzelturnierId'];
            let rangierungId = params['rangierungId']
            this.readSaison(+saisonId);
            this.readTurnierSerie(+turnierId, einzelturnierId, rangierungId);
            this.readMitgliedList();
          }
        );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

    readTurnierSerie(turnierId: number, einzelturnierId: number, rangierungId: number): void {
      this.turnierserieService.getTurnierSerie(turnierId)
        .subscribe(
          turnierserie => {
            this.turnierserie = turnierserie;
            this.einzelturnier = turnierserie.einzelturniere.find(einzelturnier => einzelturnier.id==einzelturnierId);

            if (!isNaN(rangierungId)) {
              this.rangierungId = rangierungId;
              let rangierung: EinzelturnierRangierung = this.einzelturnier.rangliste.find(rangierung => rangierung.id==rangierungId);
              this.personId = rangierung.personId;
              this.resultat = rangierung.resultat;
            }
          }
        );
    }

    readMitgliedList(): void {
      this.mitgliedService.getMitgliedList$().subscribe(
        mitgliedList => this.mitgliedList = mitgliedList.filter(mitglied => mitglied.isAktiv)
      );
    }

    name(personId: number): string {
      let mitglied = this.mitglied(personId);
      if (mitglied) {
        return `${mitglied.vorname} ${mitglied.name}`;
      } else {
        return '';
      }
    }

    private mitglied(personId: number): Mitglied {
      return this.mitgliedList.find(mitglied => mitglied.id === personId);
    }

    onSubmit() {
      let person = this.mitglied(this.personId);
      let rangierung: EinzelturnierRangierung = {
        id: this.rangierungId,
        rang: null,
        personId: this.personId,
        vorname: person?.vorname,
        name: person?.name,
        resultat: this.resultat
      };

      this.turnierserieService.saveRangierung(this.turnierserie.id, this.einzelturnier.id, rangierung).subscribe({
          next: data => {
            console.log("Save successful");
            this.router.navigate(['/saisons', this.saison.id, 'turniere', this.turnierserie.id, 'einzelturnier-detail-edit', this.einzelturnier.id]);
          },
          error: error => {
            console.error("Save error: ", error.message);
            // TODO Status-Service
          }
      });
    }

    private logError(message: string) {
      this.statusService.add(message);
    }
}
