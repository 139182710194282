import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { Rundenturnier, Turnierrunde, Turnierpaarung } from '../../runden-turnier/rundenturnier';
import { RundenturnierService } from '../../runden-turnier/rundenturnier.service';
import { StatusService } from '../../../status/status.service';
import { AuthService } from '../../../auth/auth.service';
import { MitgliedService } from '../../../mitglied/mitglied.service';
import { Mitglied } from '../../../mitglied/mitglied';

@Component({
  selector: 'app-turnier-runde-detail-edit',
  templateUrl: './turnier-runde-detail-edit.component.html',
  styleUrls: ['./turnier-runde-detail-edit.component.scss']
})
export class TurnierRundeDetailEditComponent implements OnInit {

  saison: Saison;
  rundenturnier: Rundenturnier;
  turnierrunde: Turnierrunde;
  mitgliedList: Mitglied[];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private mitgliedService: MitgliedService,
    private saisonService: SaisonService,
    private rundenturnierService: RundenturnierService,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          let turnierrundeId = params['turnierrundeId'];
          this.readSaison(+saisonId);
          this.readRundenTurnier(+turnierId, turnierrundeId);
        }
      );

    this.mitgliedService.getMitgliedList$().subscribe(mitgliedList => this.mitgliedList = mitgliedList);
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  private belongsToPaarung(paarung: Turnierpaarung): boolean {
    return this.belongsToSpieler(paarung.weissPersonId) || this.belongsToSpieler(paarung.schwarzPersonId);
  }

  private belongsToSpieler(mitgliedId: number) {
    if (mitgliedId === undefined) {
      return false;
    }

    const mitglied = this.mitgliedList
      .find(mitglied => mitglied.id === mitgliedId);

    if (mitglied === undefined) {
      return false;
    }

    return this.authService.isUser(mitglied.userid);
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readRundenTurnier(turnierId: number, turnierrundeId: number): void {
    this.rundenturnierService.getRundenturnier(turnierId)
      .subscribe(
        rundenturnier => {
          this.rundenturnier = rundenturnier;

          if (isNaN(turnierrundeId)) {
            this.turnierrunde = {
              id: null,
              runde: null,
              datum: null,
              paarungen: [],
              canBeDeleted: true
            }
          } else {
            this.turnierrunde = rundenturnier.turnierrunden.find(turnierrunde => turnierrunde.id==turnierrundeId);
          }
        }
      );
  }

  deletePaarung(paarungId: number) {
    this.rundenturnierService.deleteTurnierPaarung(this.rundenturnier.id, this.turnierrunde.id, paarungId)
          .subscribe({
            next: data => {
              console.log("Delete successful");
              this.readRundenTurnier(this.rundenturnier.id, this.turnierrunde.id);
            },
            error: error => {
              console.error("Delete error: ", error.message);
              this.logError(`Delete failed: ${error.message}`);
            }
          });
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faArrowCircleLeft = faArrowCircleLeft;
}
