import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { Rundenturnier, Turnierrunde } from '../../runden-turnier/rundenturnier';
import { RundenturnierService } from '../../runden-turnier/rundenturnier.service';

@Component({
  selector: 'app-turnier-runde-edit',
  templateUrl: './turnier-runde-edit.component.html',
  styleUrls: ['./turnier-runde-edit.component.scss']
})
export class TurnierRundeEditComponent implements OnInit {

  saison: Saison;
  rundenturnier: Rundenturnier;
  turnierrunde: Turnierrunde;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private saisonService: SaisonService,
    private rundenturnierService: RundenturnierService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          let turnierrundeId = params['turnierrundeId'];
          this.readSaison(+saisonId);
          this.readRundenTurnier(+turnierId, turnierrundeId);
        }
      );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readRundenTurnier(turnierId: number, turnierrundeId: number): void {
    this.rundenturnierService.getRundenturnier(turnierId)
      .subscribe(
        rundenturnier => {
          this.rundenturnier = rundenturnier;

          if (isNaN(turnierrundeId)) {
            this.turnierrunde =
              {
                id: null,
                runde: null,
                datum: null,
                paarungen: [],
                canBeDeleted: true
              }
          } else {
            this.turnierrunde = rundenturnier.turnierrunden.find(turnierrunde => turnierrunde.id==turnierrundeId);
          }
        }
      );
  }

  onSubmit() {
    this.rundenturnierService.saveTurnierrunde(this.rundenturnier.id, this.turnierrunde).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['saisons', this.saison.id, 'turniere', this.rundenturnier.id, 'rundenturnier-detail-edit']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
