<div class="container">
  <h2>Vorstand</h2>
  <div *ngIf="vorstand" class="d-none d-lg-block">
    Der Vorstand des Schachklubs Düdingen setzt sich wie folgt zusammen:
    <table class="table table-sm mt-3">
      <thead>
      <tr class="d-flex">
        <th class="col-2">Rolle</th>
        <th class="col-2">Name</th>
        <th class="col-3">Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let vorstandsmitglied of vorstand" class="d-flex">
        <td class="col-2">{{vorstandsmitglied.vorstandsrolleDescription}}</td>
        <td class="col-2">{{vorstandsmitglied.vorname}} {{vorstandsmitglied.name}}</td>
        <td class="col-3">
          <div *ngIf="vorstandsmitglied.showContactDetails">
            <div class="row m-0">
              {{vorstandsmitglied.email}}
            </div>
            <div class="row m-0">
              {{phone(vorstandsmitglied)}}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="vorstand" class="d-none d-sm-block d-lg-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-3">Rolle</th>
        <th class="col-4">Name</th>
        <th class="col-5">Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let vorstandsmitglied of vorstand" class="d-flex">
        <td class="col-3">{{vorstandsmitglied.vorstandsrolleDescription}}</td>
        <td class="col-4">{{vorstandsmitglied.vorname}} {{vorstandsmitglied.name}}</td>
        <td class="col-5">
          <div *ngIf="vorstandsmitglied.showContactDetails">
            <div class="row m-0">
              {{vorstandsmitglied.email}}
            </div>
            <div class="row m-0">
              {{phone(vorstandsmitglied)}}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="vorstand" class="d-sm-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-4">Rolle</th>
        <th class="col-8">Name / Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let vorstandsmitglied of vorstand" class="d-flex">
        <td class="col-4">{{vorstandsmitglied.vorstandsrolleDescription}}</td>
        <td class="col-8">
          <div class="row m-0">
            {{vorstandsmitglied.vorname}} {{vorstandsmitglied.name}}
          </div>
          <div *ngIf="vorstandsmitglied.showContactDetails">
            <div class="row m-0">
              {{vorstandsmitglied.email}}
            </div>
            <div class="row m-0">
              {{phone(vorstandsmitglied)}}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
