import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Rundenturnier, Turnierrunde, Turnierpaarung } from './rundenturnier';
import { StatusService } from '../../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class RundenturnierService {

  private urlPrefix = 'api/turnier/rundenturnier';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient,
      private statusService: StatusService
  ) { }

  getRundenturnier(turnierId: number): Observable<Rundenturnier> {
        let url = `${this.urlPrefix}/${turnierId}`
        return this.http.get<Rundenturnier>(url)
          .pipe(
            catchError(this.handleError<Rundenturnier>('getRundenturnier'))
          );
  }

  saveTurnierrunde(turnierId: number, turnierrunde: Turnierrunde): Observable<Rundenturnier> {
    let url = `${this.urlPrefix}/${turnierId}/turnierrunde`
    if (turnierrunde.id) {
      return this.http.put<Rundenturnier>(url, turnierrunde, this.httpOptions)
          .pipe(
            catchError(this.handleError<Rundenturnier>('updateTurnierrunde'))
          );
    } else {
      return this.http.post<Rundenturnier>(url, turnierrunde, this.httpOptions)
          .pipe(
            catchError(this.handleError<Rundenturnier>('addTurnierrunde'))
          );
    }
  }

  saveTurnierPaarung(turnierId: number, turnierrundeId: number, turnierpaarung: Turnierpaarung): Observable<Rundenturnier> {
    let url = `${this.urlPrefix}/${turnierId}/turnierrunde/${turnierrundeId}/paarung`
    if (turnierpaarung.id) {
      return this.http.put<Rundenturnier>(url, turnierpaarung, this.httpOptions)
          .pipe(
            catchError(this.handleError<Rundenturnier>('updateTurnierpaarung'))
          );
    } else {
      return this.http.post<Rundenturnier>(url, turnierpaarung, this.httpOptions)
          .pipe(
            catchError(this.handleError<Rundenturnier>('addTurnierpaarung'))
          );
    }
  }

  deleteTurnierrunde(turnierId: number, turnierrundeId: number): Observable<Object> {
      let url = `${this.urlPrefix}/${turnierId}/turnierrunde/${turnierrundeId}`
      return this.http.delete(url, this.httpOptions)
        .pipe(
          catchError(this.handleError<Turnierrunde>('deleteTurnierrunde'))
        );
  }

  deleteTurnierPaarung(turnierId: number, turnierrundeId: number, paarungId: number): Observable<Object> {
      let url = `${this.urlPrefix}/${turnierId}/turnierrunde/${turnierrundeId}/paarung/${paarungId}`
      return this.http.delete(url, this.httpOptions)
        .pipe(
          catchError(this.handleError<Turnierpaarung>('deleteTurnierPaarung'))
        );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.statusService.add(message);
  }
}
