<div class="einzelturnierEdit container" *ngIf="saison && turnierserie && einzelturnier">
  <h3 class="my-4">
    <div>H3: First</div>
    <span class="d-none d-md-inline">{{turnierserie.bezeichnung}} ({{saison.bezeichnung}}) - </span>
        <span>Runde {{einzelturnier.runde}}: {{einzelturnier.bezeichnung}}</span>
        <span class="d-none d-sm-inline"> ({{einzelturnier.datum | date: 'dd.MM.yyyy'}})</span>
  </h3>

  <form (ngSubmit)="onSubmit()" #einzelturnierForm="ngForm">
    <div class="form-group">
      <label for="datum">Datum</label>
      <input type="date" class="form-control" required
             id="datum"
             [(ngModel)]="datum" name="datum">
    </div>

    <div class="form-group">
      <label for="einzelturnierTyp">Turnier-Typ</label>
      <select id="einzelturnierTyp" class="form-control"
              [(ngModel)]="einzelturnierTyp" name="einzelturnierTyp">
        <option *ngFor="let einzelturnierTyp of einzelturnierTypList"
                [ngValue]="einzelturnierTyp">
          <span>{{einzelturnierTyp}}</span>
        </option>
      </select>
    </div>

    <a class="btn btn-outline-secondary" [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'turnierserie-detail-edit']">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>



