import { Component, OnChanges, Input } from '@angular/core';
import * as _ from 'lodash';

import { SaisonTurnier, SaisonTurnierTyp } from '../../../turnier/saison-turnier/saison-turnier';
import { RundenturnierService } from '../../../turnier/runden-turnier/rundenturnier.service';
import { Rundenturnier, RundenturnierRangierung } from '../../../turnier/runden-turnier/rundenturnier';
import { TurnierSerieService } from '../../../turnier/turnier-serie/turnier-serie.service';
import { TurnierSerie, TurnierserieRangierung } from '../../../turnier/turnier-serie/turnier-serie';

@Component({
  selector: 'app-rangliste-overview',
  templateUrl: './rangliste-overview.component.html',
  styleUrls: ['./rangliste-overview.component.scss']
})
export class RanglisteOverviewComponent implements OnChanges {

  @Input() saisonTurnier: SaisonTurnier;
  topRangierungen: Rangierung[];
  spitzenrangierungen: string[];

  constructor(
    private rundenturnierService: RundenturnierService,
    private turnierSerieService: TurnierSerieService
  ) { }

  ngOnChanges(): void {
    if (this.saisonTurnier) {
      this.readRangliste();
    }
  }

  private readRangliste() {
          switch(this.saisonTurnier.saisonTurnierTyp) {
            case 'RUNDEN_TURNIER':
              this.readRundenTurnierRangliste();
              break;

            case 'EINZEL_TURNIER_SERIE':
            case 'MULTI_DISZIPLINEN_CUP':
              this.readTurnierSerieRangliste();
          }
  }

  private readRundenTurnierRangliste() {
    this.rundenturnierService.getRundenturnier(this.saisonTurnier.id).subscribe(
      rundenturnier => {
        if (rundenturnier.rangliste) {
          this.topRangierungen = rundenturnier.rangliste
            .filter(rangierung => rangierung.rang <= 3)
            .map(rangierung => new Rangierung(rangierung.rang, `${rangierung.vorname} ${rangierung.name}`));
          this.formatRangliste();
        }
      }
    );
  }

  private readTurnierSerieRangliste() {
    this.turnierSerieService.getTurnierSerie(this.saisonTurnier.id).subscribe(
      turnierserie => {
        if (turnierserie.rangliste && turnierserie) {
          this.topRangierungen = turnierserie.rangliste.rangierungen
            .filter(rangierung => rangierung.rang <= 3)
            .map(rangierung => new Rangierung(rangierung.rang, `${rangierung.vorname} ${rangierung.name}`));
          this.formatRangliste();
        }
      }
    );
  }

  private formatRangliste() {
      const topRangierungenByRang = _.groupBy(this.topRangierungen, rangierung => rangierung.rang);
      this.spitzenrangierungen = [];
      for (let rang in topRangierungenByRang) {
        const rangierung = topRangierungenByRang[rang];
        const spieler = this.formatSpieler(rangierung);
        this.spitzenrangierungen.push(`${rang}. ${spieler}`)
      }
  }

  private formatSpieler(rangierungen: Rangierung[]): string {
    return rangierungen
      .map(rangierung=>rangierung.name)
      .join(', ');
  }
}

class Rangierung {
  constructor(rang: number, name: string) {
    this.rang = rang;
    this.name = name;
  }

  rang: number;
  name: string;
}
