<div class="dokumentEdit container">
  <h2>Dokument</h2>
  <form (ngSubmit)="onSubmit()" #dokumentForm="ngForm">
    <div class="form-group">
      <label>Beschreibung</label>
      <input type="text" class="form-control" placeholder="Beschreibung"
             id="description"
             [(ngModel)]="dokument.description" name="description">
    </div>
    <div class="form-group">
      <label>Effektiver Filename</label>
      <input type="text" placeholder="Beim Download verwendeter Dateiname..." class="form-control" required
             id="filename"
             [(ngModel)]="dokument.filename" name="filename">
    </div>
        <div class="form-group mb-4">
          <label>Datei</label>
          <div class="input-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="dokumentFile" (change)="chooseFileToUpload($event.target.files)">
              <label class="custom-file-label bg-light text-secondary" for="dokumentFile" id="upload-file-info">{{fileToUpload.name}}<span *ngIf="!dokument.filename">Datei auswählen...</span></label>
            </div>
          </div>
        </div>
    <a class="btn btn-outline-secondary" routerLink="/dokumente" routerLinkActive="active">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>
