import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Mitglied } from '../mitglied';
import { Elozahl } from '../elozahl';
import { MitgliedService } from '../mitglied.service';
import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';

@Component({
  selector: 'app-mitglied-elo-list',
  templateUrl: './mitglied-elo-list.component.html',
  styleUrls: ['./mitglied-elo-list.component.scss']
})
export class MitgliedEloListComponent implements OnInit {

  mitglied: Mitglied;
  elozahlen$: Observable<Elozahl[]>;
  elozahlen: Elozahl[];

  saisonList$: Observable<Saison[]>;
  saisonList: Saison[];
  currentSaison: Saison;

  constructor(private route: ActivatedRoute, private mitgliedService: MitgliedService, private saisonService: SaisonService) { }

  ngOnInit(): void {
      this.route.params
        .subscribe(
          (params: Params) => this.readMitglied(+params['mitgliedId'])
        );

    this.readSaisonList();
  }

  private readMitglied(id: number) {
      this.mitgliedService.getMitglied$(id)
        .subscribe((mitglied) => this.mitglied = mitglied);

      this.elozahlen$ = this.mitgliedService.getElozahlen$(id);
      this.elozahlen$.subscribe((elozahlen) => this.elozahlen = elozahlen);
  }

  readSaisonList(): void {
    this.saisonList$ = this.saisonService.getSaisonList$();
    this.saisonList$
      .subscribe(saisonList => {
        this.saisonList = saisonList;
        this.currentSaison = saisonList [0];
      });
  }

  getSaison(saisonId: number): Saison {
    return this.saisonList.filter(saison => saison.id === saisonId)[0];
  }

  addElozahl() {
    this.elozahlen.push({
      id: null,
      fkSaison: this.currentSaison.id,
      validFrom: this.currentSaison.startDatum,
      elo: null
    });
  }

  deleteElozahl(elozahlId: number) {
    this.elozahlen = this.elozahlen.filter(elozahl => elozahl.id !== elozahlId);
  }

  faPlus = faPlus;
  faTrash = faTrash;
}
