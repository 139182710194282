<div class="container">
  <h2>Kontakt</h2>
  <div *ngIf="kontakte" class="d-none d-lg-block">
    Für direkte Fragen und weiteren Informationen zum Schachklub Düdingen stehen folgende Kontaktmöglichkeiten zur Verfügung:
    <table class="table table-sm mt-3">
      <thead>
      <tr class="d-flex">
        <th class="col-2">Rolle</th>
        <th class="col-2">Name</th>
        <th class="col-3">Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let kontakt of kontakte" class="d-flex">
        <td class="col-2"><span *ngIf="kontakt.vorstandsrolle">{{kontakt.vorstandsrolleDescription}}</span></td>
        <td class="col-2">{{kontakt.vorname}} {{kontakt.name}}</td>
        <td class="col-3">
          <div class="row m-0">
            {{kontakt.email}}
          </div>
          <div class="row m-0">
            {{phone(kontakt)}}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="kontakte" class="d-none d-sm-block d-lg-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-3">Rolle</th>
        <th class="col-4">Name</th>
        <th class="col-5">Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let kontakt of kontakte" class="d-flex">
        <td class="col-3">{{kontakt.vorstandsrolleDescription}}</td>
        <td class="col-4">{{kontakt.vorname}} {{kontakt.name}}</td>
        <td class="col-5">
          <div *ngIf="kontakt.showContactDetails">
            <div class="row m-0">
              {{kontakt.email}}
            </div>
            <div class="row m-0">
              {{phone(kontakt)}}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="kontakte" class="d-sm-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-4">Rolle</th>
        <th class="col-8">Name / Kontakt</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let kontakt of kontakte" class="d-flex">
        <td class="col-4">{{kontakt.vorstandsrolleDescription}}</td>
        <td class="col-8">
          <div class="row m-0">
            {{kontakt.vorname}} {{kontakt.name}}
          </div>
          <div *ngIf="kontakt.showContactDetails">
            <div class="row m-0">
              {{kontakt.email}}
            </div>
            <div class="row m-0">
              {{phone(kontakt)}}
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
