<table class="table table-sm col-12 col-md-9 col-lg-8 col-xl-7">
  <thead>
  <tr class="d-flex thead-light">
    <th class="col-5 col-md-6">Runde {{turnierrunde.runde}}</th>
    <th class="col-7 col-md-6">{{turnierrunde.datum | date: 'dd.MM.yyyy'}}</th>
  </tr>

  </thead>
  <tbody>
  <tr *ngFor="let paarung of turnierrunde.paarungen" class="d-flex">
    <td class="col-4 col-md-5">{{paarung.weissVorname}} {{paarung.weissName}}</td>
    <td class="col-1">-</td>
    <td class="col-4 col-md-5">
      <span *ngIf="paarung.schwarzPersonId">{{paarung.schwarzVorname}} {{paarung.schwarzName}}</span>
      <span *ngIf="!paarung.schwarzPersonId" class="text-muted"><em>spielfrei</em></span>
    </td>
    <td class="col-2 col-md-1 text-center">
      <span *ngIf="paarung.schwarzPersonId">{{paarung.resultatBezeichnung}}</span>
    </td>
    <td class="col-1 col-sm-2"> <!-- TODO Layout + Router-Link korrigieren.-->
      <a *ngIf="isAdmin() || paarungBelongsToAuthorizedUser(paarung)" class="btn btn-secondary px-1 py-0 ml-1 float-right"
         title="Editieren"
         [routerLink]="['/saisons', saisonId, 'turniere', turnierId, 'turnierrunde', turnierrunde.id, 'paarung', paarung.id]"
         routerLinkActive="active">
        <fa-icon [icon]="faEdit" size="s"></fa-icon>
      </a>
    </td>
  </tr>
  <tr *ngIf="turnierrunde.paarungen?.length===0">Noch keine Paarungen vorhanden</tr>
  </tbody>
</table>

