import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      { name: 'description', content: 'Website des Schachklub Düdingen'},
      { name: 'author', content: 'Bernhard Ruch'},
      { name: 'keywords', content: 'Schach, Schachklub, Düdingen, Senseschach, Sensebezirk, Freiburg, FR' }
    ]);

    this.setTitle("Schachklub Düdingen");
  }

  public setTitle (newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
  }
}
