<div *ngIf="rundenturnier && saison" class="rundenTurnierDetailEdit container">
  <h3>{{rundenturnier.bezeichnung}} <span class="d-none d-sm-inline">({{saison.bezeichnung}})</span></h3>
  <table class="table table-sm">
    <thead>
    <tr class="d-flex thead-light">
      <th class="col-3 col-sm-2">Runde</th>
      <th class="col-4 col-sm-3">Datum</th>
      <th class="col-4 col-sm-2"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let turnierrunde of rundenturnier.turnierrunden">
      <td class="col-3 col-sm-2">{{turnierrunde.runde}}</td>
      <td class="col-4 col-sm-3">{{turnierrunde.datum | date: 'dd.MM.yyyy'}}</td>
      <td class="col-4 col-sm-2">
        <button class="btn btn-danger px-1 py-0 ml-1 float-right"
                title="Löschen"
                [disabled]="!turnierrunde.canBeDeleted"
                (click)="deleteTurnierrunde(turnierrunde)">
          <fa-icon [icon]="faTrash" size="s"></fa-icon>
        </button>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'turnierrunde', turnierrunde.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faEdit" size="s"></fa-icon>
        </a>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Details editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'turnierrunde-detail-edit', turnierrunde.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faThList" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    <tr class="d-flex">
      <td class="col-3 col-sm-2">
        <a class="btn btn-primary btn-sm"
           title="Runde hinzufügen"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'turnierrunde', 'new']"
           routerLinkActive="active">
          <fa-icon [icon]="faPlus" size="s"></fa-icon>
        </a>
      </td>
      <td class="col-4 col-sm-3"></td>
      <td class="col-4 col-sm-2">
        <a class="btn btn-secondary btn-sm float-right"
           title="Zurück"
           [routerLink]="['/saisons', saison.id, 'turniere']"
           routerLinkActive="active">
          <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
