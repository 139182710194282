import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-turnier',
  templateUrl: './turnier.component.html',
  styleUrls: ['./turnier.component.scss']
})
export class TurnierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
