import { Component, OnInit } from '@angular/core';

import { Termin } from '../../termin/termin';
import { TerminService} from '../../termin/termin.service';

@Component({
  selector: 'app-termin-overview',
  templateUrl: './termin-overview.component.html',
  styleUrls: ['./termin-overview.component.scss']
})
export class TerminOverviewComponent implements OnInit {

  // TODO Gemeinsamkeiten mit termin-list.ts nutzen; eine einzige Komponente zur Anzeige von Termin-Listen erstellen

  terminList: Termin[];

  constructor(private terminService: TerminService) { }

  getTerminList(): void {
    this.terminService.getTerminOverviewList(4)
      .subscribe(terminList =>
        this.terminList = terminList
      );
  }

  ngOnInit(): void {
    this.getTerminList();
  }
}
