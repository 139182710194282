import { Component, OnInit } from '@angular/core';
import { Mitglied, Vorstandsrolle } from '../mitglied/mitglied';
import { MitgliedService } from '../mitglied/mitglied.service';

@Component({
  selector: 'app-vorstand',
  templateUrl: './vorstand.component.html',
  styleUrls: ['./vorstand.component.scss']
})
export class VorstandComponent implements OnInit {

  vorstand: Mitglied[];

  constructor(private mitgliedService: MitgliedService) { }

  ngOnInit(): void {
    this.mitgliedService.getMitgliedList$().subscribe(
      mitgliedList => this.extractVorstand(mitgliedList)
    );
  }

  private extractVorstand(mitgliedList: Mitglied[]) {
    this.vorstand = mitgliedList
      .filter(mitglied => mitglied.vorstandsrolle)
      .sort((a, b) => this.compareByVorstandsrolle(a, b));
  }

  private compareByVorstandsrolle(mitglied1: Mitglied, mitglied2: Mitglied): number {
    return Object.keys(Vorstandsrolle).indexOf(mitglied1.vorstandsrolle) - Object.keys(Vorstandsrolle).indexOf(mitglied2.vorstandsrolle);
  }

  phone(mitglied: Mitglied): string {
    return (mitglied.telMobil)?
      mitglied.telMobil:
      (mitglied.telPrivat)?
        mitglied.telPrivat:
        mitglied.telGeschaeft;
  }

}
