import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash, faThList, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { Rundenturnier, Turnierrunde } from '../runden-turnier/rundenturnier';
import { RundenturnierService } from '../runden-turnier/rundenturnier.service';
import { StatusService } from '../../status/status.service';

@Component({
  selector: 'app-runden-turnier-detail-edit',
  templateUrl: './runden-turnier-detail-edit.component.html',
  styleUrls: ['./runden-turnier-detail-edit.component.scss']
})
export class RundenTurnierDetailEditComponent implements OnInit {

  saison: Saison;
  rundenturnier: Rundenturnier;

  constructor(
            private route: ActivatedRoute,
            private saisonService: SaisonService,
            private rundenturnierService: RundenturnierService,
            private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          this.readSaison(+saisonId);
          this.readRundenTurnier(+turnierId);
        }
      );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readRundenTurnier(turnierId: number): void {
    this.rundenturnierService.getRundenturnier(turnierId)
      .subscribe(
        rundenturnier => {
          this.rundenturnier = rundenturnier;
        }
      );
  }

  public deleteTurnierrunde(turnierrunde: Turnierrunde) {
    this.rundenturnierService.deleteTurnierrunde(this.rundenturnier.id, turnierrunde.id)
          .subscribe({
            next: data => {
              console.log("Delete successful");
              this.readRundenTurnier(this.rundenturnier.id);
            },
            error: error => {
              console.error("Delete error: ", error.message);
              this.logError(`Delete failed: ${error.message}`);
            }
          });
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faThList = faThList;
  faArrowCircleLeft = faArrowCircleLeft;
}
