import { Elozahl } from './elozahl'

export interface Mitglied {
  id: number;
  ssbNummer: string;
  vorname: string;
  name: string;
  adresse: string;
  plz: string;
  ort: string;
  telPrivat: string;
  telMobil: string;
  telGeschaeft: string;
  email: string;
  userid: string;
  isAktiv: boolean;
  elo: number;
  elozahlen: Elozahl[];
  vorstandsrolle: Vorstandsrolle;
  vorstandsrolleDescription: string;
  showContactDetails: boolean;
  juniorenLeiter: boolean;
}

export enum Vorstandsrolle {
    PRAESIDENT = 'Präsident',
    KASSIER = 'Kassier',
    SPIELLEITER = 'Spielleiter',
    SEKRETAER = 'Sekretär',
    VORSTANDSMITGLIED = 'Vorstandsmitglied'
}
