<div class="turnierList container">
    <h3>Turniere</h3>

    <div *ngIf="isAdmin()" class="form-group">
        <label for="saisonSelect">Saison</label>
        <select id="saisonSelect" class="form-control"
                [compareWith]="compareById"
                [(ngModel)]="selectedSaison" name="saison"
                (change)="onSaisonChange()">
            <option *ngFor="let saison of saisonList"
                    [ngValue]="saison">{{saison.bezeichnung}}</option>
        </select>
    </div>


  <table class="table table-sm" *ngIf="turnierList">
    <thead>
    <tr class="d-flex">
      <th class="col-7 col-sm-4 col-lg-5">Bezeichnung</th>
      <th class="col-sm-5 d-none d-sm-block">Typ</th>
      <th class="col-5 col-sm-3 col-lg-2"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let turnier of turnierList" class="d-flex">
      <td class="col-7 col-sm-4 col-lg-5">
        {{turnier.bezeichnung}}
      </td>
      <td class="col-sm-5 d-none d-sm-block">
        {{turnier.typ}}
      </td>
      <td class="col-5 col-sm-3 col-lg-2">
        <button class="btn btn-danger px-1 py-0 ml-1 float-right"
                title="Löschen"
                [disabled]="!canBeDeleted(turnier)"
                (click)="deleteTurnier(turnier)">
          <fa-icon [icon]="faTrash" size="s"></fa-icon>
        </button>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Editieren"
           [routerLink]="['/saisons', selectedSaison.id, 'turniere', turnier.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faEdit" size="s"></fa-icon>
        </a>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Details editieren"
           [routerLink]="['/saisons', selectedSaison.id, 'turniere', turnier.id, detailEditDiskriminator(turnier)]"
           routerLinkActive="active">
          <fa-icon [icon]="faThList" size="s"></fa-icon>
        </a>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Resultate"
           [routerLink]="['/saisons', selectedSaison.id, 'turniere', turnier.id, detailDiskriminator(turnier)]"
           routerLinkActive="active">
          <fa-icon [icon]="faListOl" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    <tr class="d-flex">
      <th class="col-7 col-sm-4 col-lg-5">
        <a *ngIf="isAdmin() && selectedSaison" class="btn btn-primary btn-sm"
           title="Turnier hinzufügen"
           [routerLink]="['/saisons', selectedSaison.id, 'turniere', 'new']"
           [state]="{}"
           routerLinkActive="active">
          <fa-icon [icon]="faPlus" size="s"></fa-icon>
        </a>
      </th>
      <th class="col-sm-5 d-none d-sm-block"></th>
      <th class="col-5 col-sm-3 col-lg-2">
        <a *ngIf="selectedSaison" class="btn btn-secondary btn-sm float-right"
           title="Zurück"
           [routerLink]="['/']"
           routerLinkActive="active">
          <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
        </a>
      </th>
    </tr>
    </tbody>
  </table>

</div>
