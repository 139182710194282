import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


import { Dokument } from '../dokument';
import { DokumentService } from '../dokument.service';

@Component({
  selector: 'app-dokument-edit',
  templateUrl: './dokument-edit.component.html',
  styleUrls: ['./dokument-edit.component.scss']
})
export class DokumentEditComponent implements OnInit {

  dokument: Dokument;
  fileToUpload: File = null;

  constructor(private router: Router, private route: ActivatedRoute, private dokumentService: DokumentService) { }

  ngOnInit(): void {
    this.route.params
        .subscribe(
          (params: Params) => {
              const dokumentId = params['dokumentId'];
              if (isNaN(dokumentId)) {
                this.newDokument();
              } else {
                this.readDokument(+dokumentId);
              }
          }
        );
  }

  private readDokument(dokumentId: number) {
      this.dokumentService.getDokument(dokumentId)
        .subscribe((dokument) => this.dokument = dokument);
  }

  private newDokument() {
      this.dokument = {
        id: null,
        href: '',
        filename: '',
        description: 'Test',
        datum: new Date('2020-12-12') // TODO
      };
  }

  onSubmit() {
    this.dokumentService.saveDokument(this.dokument.id, this.fileToUpload, this.dokument.filename, this.dokument.description).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['/dokumente']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }

  chooseFileToUpload(files: FileList) {
    this.fileToUpload = files.item(0);
    this.dokument.filename=this.fileToUpload.name;
  }
}
