import { Component, OnInit } from '@angular/core';
import { faDownload, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import * as fileSaver from 'file-saver';


import { Dokument } from '../dokument';
import { DokumentService } from '../dokument.service';
import { StatusService } from '../../status/status.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-dokument-list',
  templateUrl: './dokument-list.component.html',
  styleUrls: ['./dokument-list.component.scss']
})
export class DokumentListComponent implements OnInit {

  dokumentList: Dokument[];

  constructor(private authService: AuthService, private dokumentService: DokumentService, private statusService: StatusService) { }

  ngOnInit(): void {
    this.readDokumentList();
  }

  readDokumentList(): void {
    this.dokumentService.getDokumentList()
      .subscribe(dokumentList =>
        this.dokumentList = dokumentList
      );
  }

  deleteDokument(dokumentId: number) {
    this.dokumentService.deleteDokument(dokumentId)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.readDokumentList();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      })
  }

  downloadDokument(dokument: Dokument) {
    this.dokumentService.downloadDokument(dokument.id)
      .subscribe({
        next: response => {
          console.log("Download successful");
          let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
    			fileSaver.saveAs(blob, dokument.filename);
        },
        error: error => {
          console.error("Download error: ", error.message);
          this.logError(`Download failed: ${error.message}`);
        }
      })
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  isMember(): boolean {
    return this.authService.isMember;
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  faDownload = faDownload;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
}
