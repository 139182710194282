import { Component, Input, OnInit } from '@angular/core';
import { Rundenturnier } from '../../runden-turnier/rundenturnier';

@Component({
  selector: 'app-rundenturnier-rangliste',
  templateUrl: './rundenturnier-rangliste.component.html',
  styleUrls: ['./rundenturnier-rangliste.component.scss']
})
export class RundenturnierRanglisteComponent implements OnInit {

  @Input() rundenturnier: Rundenturnier;

  constructor() { }

  ngOnInit(): void {
  }

}
