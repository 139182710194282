import { Component, OnInit } from '@angular/core';

import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';

@Component({
  selector: 'app-turnier-overview',
  templateUrl: './turnier-overview.component.html',
  styleUrls: ['./turnier-overview.component.scss']
})
export class TurnierOverviewComponent implements OnInit {

  currentSaison: Saison;
  lastSaison: Saison;

  constructor(
    private saisonService: SaisonService
  ) { }

  ngOnInit(): void {
    this.saisonService.getCurrentSaison()
      .subscribe(saison => {
        this.currentSaison = saison;
      });
    this.saisonService.getLastSaison()
      .subscribe(saison => {
        this.lastSaison = saison;
      });
  }

}
