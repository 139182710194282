import { Component, OnInit } from '@angular/core';
import { faPlus, faEdit, faTrash, faCalendarAlt, faThList } from '@fortawesome/free-solid-svg-icons';

import { Saison } from '../saison';
import { SaisonService } from '../saison.service';
import { StatusService } from '../../status/status.service';

@Component({
  selector: 'app-saison-list',
  templateUrl: './saison-list.component.html',
  styleUrls: ['./saison-list.component.scss']
})
export class SaisonListComponent implements OnInit {

  constructor(private saisonService: SaisonService, private statusService: StatusService) { }

  saisonList: Saison[];

  ngOnInit(): void {
    this.getSaisonList();
  }

  getSaisonList(): void {
    this.saisonService.getSaisonList$()
      .subscribe(saisonList =>
        this.saisonList = saisonList
      )
  }

  deleteSaison(saisonId: number) {
    this.saisonService.deleteSaison(saisonId)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.getSaisonList();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      });
  }

  canBeDeleted(saison: Saison): boolean {
    if (saison.id > 10) { // TODO
      return true;
    }
    return false;
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;
  faThList = faThList;
}
