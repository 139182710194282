import { Component, Input, OnInit } from '@angular/core';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import * as fileSaver from 'file-saver';

import { Information } from '../information';
import { DokumentService } from '../../dokument/dokument.service';
import { Dokument } from '../../dokument/dokument';
import { StatusService } from '../../status/status.service';


@Component({
  selector: 'app-information-alert',
  templateUrl: './information-alert.component.html',
  styleUrls: ['./information-alert.component.scss']
})
export class InformationAlertComponent implements OnInit {

  @Input() information: Information;
  @Input() defaultMessage: string;
  @Input() small: boolean;

  constructor(private dokumentService: DokumentService, private statusService: StatusService) { }

  ngOnInit(): void {
  }

  downloadDokument(dokument: Dokument) {
    this.dokumentService.downloadDokument(dokument.id)
      .subscribe({
        next: response => {
          console.log("Download successful");
          let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
    			fileSaver.saveAs(blob, dokument.filename);
        },
        error: error => {
          console.error("Download error: ", error.message);
          this.logError(`Download failed: ${error.message}`);
        }
      })
  }

  displaySmall() {
    return this.small;
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPaperclip = faPaperclip;
}
