<button *ngIf="!isLoggedIn()" class="btn btn-secondary" (click)="login()">Login</button>
<span *ngIf="isLoggedIn()">
  <span class="d-lg-none">
    <fa-icon class="ml-1" [icon]="faUser"></fa-icon>
    <span class="ml-2">{{profile?.firstName}} {{profile?.lastName}}</span>
    <button class="btn btn-secondary ml-1" (click)="logout()">
      <fa-icon [icon]="faArrowCircleLeft" title="Logout"></fa-icon>
    </button>
  </span>
  <span class="d-none d-lg-inline d-xl-none">
    <fa-icon class="ml-1" [icon]="faUser" title="{{profile?.firstName}} {{profile?.lastName}}"></fa-icon>
    <button class="btn btn-secondary ml-1" (click)="logout()">
      <fa-icon [icon]="faArrowCircleLeft" title="Logout"></fa-icon>
    </button>
  </span>
  <span class="d-none d-xl-inline">
    <fa-icon class="ml-1" [icon]="faUser"></fa-icon>
    <span class="ml-2">{{getProfile()?.firstName}} {{getProfile()?.lastName}}</span>
    <button class="btn btn-secondary ml-1" (click)="logout()">Logout</button>
  </span>
</span>
