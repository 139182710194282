<div *ngIf="saison">
  <h6>{{description}}: {{saison.bezeichnung}}</h6>
  <table class="table table-sm">
    <thead>
    <tr class="d-flex">
      <th class="col-5 col-sm-4 col-md-3">Turnier</th>
      <th class="col-7 col-sm-8 col-md-9">Spitzenränge</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let saisonTurnier of saisonTurniere" class="d-flex">
      <td class="col-5 col-sm-4 col-md-3">
        <a class="btn btn-sm btn-info text-left" routerLink="{{getRanglisteRouterLink(saisonTurnier)}}" routerLinkActive="active">{{saisonTurnier.bezeichnung}}</a>
      </td>
      <td class="col-7 col-sm-8 col-md-9">
        <app-rangliste-overview [saisonTurnier]="saisonTurnier"></app-rangliste-overview>
      </td>
    </tr>
    </tbody>
  </table>
</div>
