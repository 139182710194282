import { Component, OnInit } from '@angular/core';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import { Information } from '../../information/information';
import { InformationService } from '../../information/information.service';

@Component({
  selector: 'app-information-overview',
  templateUrl: './information-overview.component.html',
  styleUrls: ['./information-overview.component.scss']
})
export class InformationOverviewComponent implements OnInit {

  displayInformations: Information[];

  constructor(private informationService: InformationService) { }

  getDisplayInformations(): void {
     this.informationService.getDisplayInformations()
      .subscribe(
        displayInformations =>
          this.displayInformations = displayInformations
      )
  }

  ngOnInit(): void {
    this.getDisplayInformations();
  }

  faPaperclip = faPaperclip;
}
