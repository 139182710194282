<h4>Rangliste</h4>
<table class="table table-sm table-responsive table-hover">
  <thead>
  <tr class="thead-dark">
    <th class="text-center">Rang</th>
    <th>Spieler</th>
    <th class="text-center">Punkte</th>
    <th class="text-center">Buchholz</th>
    <th class="text-center">Feinwertung</th>
    <th class="text-center">Ausstehend</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let rangierung of rundenturnier.rangliste">
    <td class="text-center">{{rangierung.rang}}</td>
    <td>{{rangierung.vorname}} {{rangierung.name}}</td>
    <td class="table-primary text-center">{{rangierung.punkte}}</td>
    <td class="text-center">{{rangierung.buchholz}}</td>
    <td class="text-center">{{rangierung.feinwertung}}</td>
    <td class="text-center">
      <span *ngIf="rangierung.anzahlAusstehendeResultate>0">{{rangierung.anzahlAusstehendeResultate}}</span>
    </td>
  </tr>
  </tbody>
</table>
