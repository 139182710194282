import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash, faThList, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { TurnierSerie, Einzelturnier } from '../turnier-serie/turnier-serie';
import { TurnierSerieService } from '../turnier-serie/turnier-serie.service';
import { StatusService } from '../../status/status.service';


@Component({
  selector: 'app-turnier-serie-detail-edit',
  templateUrl: './turnier-serie-detail-edit.component.html',
  styleUrls: ['./turnier-serie-detail-edit.component.scss']
})
export class TurnierSerieDetailEditComponent implements OnInit {

  saison: Saison;
  turnierserie: TurnierSerie;

  constructor(
              private route: ActivatedRoute,
              private saisonService: SaisonService,
              private turnierSerieService: TurnierSerieService,
              private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          this.readSaison(+saisonId);
          this.readTurnierSerie(+turnierId);
        }
      );
   }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readTurnierSerie(turnierId: number): void {
    this.turnierSerieService.getTurnierSerie(turnierId)
      .subscribe(
        turnierserie => {
          this.turnierserie = turnierserie;
        }
      );
  }

  deleteEinzelturnier(einzelturnier: Einzelturnier) {
    this.turnierSerieService.deleteEinzelturnier(this.turnierserie.id, einzelturnier.id)
        .subscribe({
          next: data => {
            console.log("Delete successful");
            this.readTurnierSerie(this.turnierserie.id);
          },
          error: error => {
            console.error("Delete error: ", error.message);
            this.logError(`Delete failed: ${error.message}`);
          }
        });
  }


  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faThList = faThList;
  faArrowCircleLeft = faArrowCircleLeft;
}
