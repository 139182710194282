<div class="header container">
  <span>
    <small>Display:
        <span class="d-inline d-sm-none">XS</span>
        <span class="d-none d-sm-inline d-md-none">SM</span>
        <span class="d-none d-md-inline d-lg-none">MD</span>
        <span class="d-none d-lg-inline d-xl-none">LG</span>
        <span class="d-none d-xl-inline">XL</span>
    </small>
  </span>
  <div class="d-none d-lg-block bg-light" >
    <img class="mt-3" src="assets/img/logo_md.png" alt="Schachklub Düdingen">
    <h4 class="d-inline-block">Schachklub Düdingen</h4>
  </div>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <!-- TODO Eventuell navbar-dark für mobile (z.B. "xs", "sm") -->
    <a class="navbar-brand" href="#">
      <div class="d-block d-sm-none">
        <img src="assets/img/logo_xs.png" alt="Schachklub Düdingen">
        <h5 class="d-inline-block">Schachklub Düdingen</h5>
      </div>
      <div class="d-none d-sm-block d-md-none">
        <img src="assets/img/logo_sm.png" alt="Schachklub Düdingen">
        <h4 class="d-inline-block">Schachklub Düdingen</h4>
      </div>
      <div class="d-none d-md-block d-lg-none">
        <img src="assets/img/logo_md.png" alt="Schachklub Düdingen">
        <h4 class="d-inline-block">Schachklub Düdingen</h4>
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav">
        <a class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/start" routerLinkActive="active">Startseite</a>
        <a class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/saisons/current/termine" routerLinkActive="active">Termine</a>
        <a *ngIf="!isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/junioren" routerLinkActive="active">Juniorenschach</a>
        <a class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/mitglieder" routerLinkActive="active">Mitglieder</a>
        <a class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/resultate" routerLinkActive="active">Resultate</a>
        <a *ngIf="!isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/vorstand" routerLinkActive="active">Vorstand</a>
        <a *ngIf="!isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/kontakt" routerLinkActive="active">Kontakt</a>
        <a class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/lokal" routerLinkActive="active">Spiellokal</a>
        <a *ngIf="isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/saisons" routerLinkActive="active">Saisons</a>
        <a *ngIf="isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/saisons/current/turniere" routerLinkActive="active">Turniere</a>
        <a *ngIf="isAdmin()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/informationen" routerLinkActive="active">Mitteilungen</a>
        <a *ngIf="isAdmin() || isMember()" class="btn btn-info mr-1 mb-1" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/dokumente" routerLinkActive="active">Dokumente</a>
        <app-auth class="d-block"></app-auth>
      </div>
    </div>
  </nav>
</div>
