import { Component, Input, OnInit } from '@angular/core';
import { Einzelturnier } from '../../turnier-serie/turnier-serie';

@Component({
  selector: 'app-einzel-turnier-rangliste',
  templateUrl: './einzel-turnier-rangliste.component.html',
  styleUrls: ['./einzel-turnier-rangliste.component.scss']
})
export class EinzelTurnierRanglisteComponent implements OnInit {

  @Input() einzelturnier: Einzelturnier;

  constructor() { }

  ngOnInit(): void {
  }

}
