<div class="container">
  <h2>Mitteilungen</h2>
  <div class="alert alert-warning"><strong>Aufgrund von COVID-19-Massnahmen finden bis auf weiteres keine
    Schachabende statt!</strong></div>
  <div class="alert alert-info">
    <span>Protokoll der Generalversammlung vom 31.08.2020</span>
    <a class="float-right" href="doc\20200901_Generalversammlung_2.pdf" download="Protokoll.pdf" target="_blank">
      <button class="btn btn-info"><span class="fas fa-download fa-sm"></span></button>
    </a>
  </div>
</div>
