export class TurnierSerie {
  id: number;
  typ: TurnierSerieTyp;
  bezeichnung: String;
  einzelturniere: Einzelturnier[];
  rangliste: TurnierSerieRangliste;
  canBeDeleted: boolean;
}

export class Einzelturnier {
  id: number;
  runde: number;
  einzelturnierTyp: EinzelturnierTyp;
  bezeichnung: String;
  datum: Date;
  rangliste: EinzelturnierRangierung[];
  canBeDeleted: boolean;
}

export class EinzelturnierRangierung {
  id: number;
  rang: number;
  personId: number;
  vorname: String;
  name: String;
  resultat: number;
}

export class TurnierSerieRangliste {
    rangierungen: TurnierserieRangierung[] ;
    anzahlTurniere: number;
    anzahlGespielteTurniere: number;
    anzahlGewerteteResultate: number;
    anzahlStreichresultate: number;
}

export class TurnierserieRangierung {
     id: number;
     rang: number;
     personId: number;
     vorname: String;
     name: String;
     einzelturnierResultate: EinzelturnierResultat[];
     punkteSumme: number;
     punkteGewertet: number;
     punkteBonusHerbst: number;
     punkteBonusFruehling: number;
     punkteMdcKurzzeitTurnier: number;
     punkteTotal: number;
}

export class EinzelturnierResultat {
    runde: number;
    punkte: number;
    isStreichresultat: boolean;
}

export enum TurnierSerieTyp {
  KURZZEIT_TURNIER_SERIE = 'KURZZEIT_TURNIER_SERIE',
  MULTI_DISZIPLINEN_CUP = 'MULTI_DISZIPLINEN_CUP'
}

export enum EinzelturnierTyp {
	JASSTURNIER = 'JASSTURNIER',
	HANDICAP = 'HANDICAP',
	BILLARD = 'BILLARD',
	WEIHNACHTSBLITZ = 'WEIHNACHTSBLITZ',
	POKER = 'POKER',
	AUFSTELL_SCHACH = 'AUFSTELL_SCHACH',
	TISCH_TENNIS = 'TISCH_TENNIS',
	JASS_SCHACH = 'JASS_SCHACH',
	BOWLING = 'BOWLING',
	BLACK_JACK = 'BLACK_JACK',
	MINIGOLF = 'MINIGOLF',
	PETANQUE = 'PETANQUE',
	BLITZ = 'BLITZ',
	KURZZEIT = 'KURZZEIT',
	MDC_KURZZEIT = 'MDC_KURZZEIT',
  MDC_BLITZ = 'MDC_BLITZ',
	GO = 'GO',
  DUCK_CHESS = 'DUCK_CHESS'
}

