<div *ngIf="turnierserie && saison" class="turnierSerieDetailEdit container">
  <h3>{{turnierserie.bezeichnung}} <span class="d-none d-sm-inline">({{saison.bezeichnung}})</span></h3>

  <table class="table table-sm">
    <thead>
    <tr class="d-flex thead-light">
      <th class="col-1 col-sm-1">Runde</th>
      <th class="col-5 col-sm-6">Turnier</th>
      <th class="col-2 col-sm-2">Datum</th>
      <th class="col-4 col-sm-3"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let einzelturnier of turnierserie.einzelturniere">
      <td class="col-1 col-sm-1">{{einzelturnier.runde}}</td>
      <td class="col-5 col-sm-6">{{einzelturnier.bezeichnung}}</td>
      <td class="col-2 col-sm-2">{{einzelturnier.datum | date: 'dd.MM.yyyy'}}</td>
      <td class="col-4 col-sm-3">
        <button class="btn btn-danger px-1 py-0 ml-1 float-right"
                title="Löschen"
                [disabled]="!einzelturnier.canBeDeleted"
                (click)="deleteEinzelturnier(einzelturnier)">
          <fa-icon [icon]="faTrash" size="s"></fa-icon>
        </button>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-edit', einzelturnier.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faEdit" size="s"></fa-icon>
        </a>
        <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Details editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-detail-edit', einzelturnier.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faThList" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    <tr class="d-flex">
      <td class="col-1 col-sm-1">
        <a class="btn btn-primary btn-sm"
           title="Einzelturnier hinzufügen"
           [routerLink]="['/saisons', saison.id, 'turniere', turnierserie.id, 'einzelturnier-edit', 'new']"
           routerLinkActive="active">
          <fa-icon [icon]="faPlus" size="s"></fa-icon>
        </a>
      </td>
      <td class="col-5 col-sm-6"></td>
      <td class="col-2 col-sm-2"></td>
      <td class="col-4 col-sm-3">
        <a class="btn btn-secondary btn-sm float-right"
           title="Zurück"
           [routerLink]="['/saisons', saison.id, 'turniere']"
           routerLinkActive="active">
          <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    </tbody>
  </table>

</div>
