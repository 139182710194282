<table class="table table-sm">
  <thead>
  <tr class="d-flex thead-light">
    <th class="col-2 col-md-1">R{{einzelturnier.runde}}</th>
    <th class="col-10 col-md-11">{{einzelturnier.bezeichnung}} ({{einzelturnier.datum | date: 'dd.MM.yyyy'}})</th>
  </tr>
  </thead>
  <tbody>
  <tr class="d-flex" *ngFor="let rangierung of einzelturnier.rangliste">
    <td class="col-2 col-md-1">{{rangierung.rang}}</td>
    <td class="col-7 col-md-9">{{rangierung.vorname}} {{rangierung.name}}</td>
    <td class="col-3 col-md-2">{{rangierung.resultat}}</td>
  </tr>
  </tbody>
  <tr *ngIf="einzelturnier.rangliste?.length===0">Noch nicht durchgeführt</tr>
</table>

