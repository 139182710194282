import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Dokument } from './dokument';
import { StatusService } from '../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class DokumentService {

  private urlPrefix = '/api/dokument';

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private http: HttpClient, private statusService: StatusService) { }

  getDokumentList(): Observable<Dokument[]> {
        let url = `${this.urlPrefix}`;
        return this.http.get<Dokument[]>(url)
          .pipe(
            catchError(this.handleError<Dokument[]>('getInformationList', []))
          );
       }

  getDokument(dokumentId: number): Observable<Dokument> {
      let url = `${this.urlPrefix}/${dokumentId}`;
      return this.http.get<Dokument>(url)
        .pipe(
          catchError(this.handleError<Dokument>('getDokument'))
        );
  }

  downloadDokument(dokumentId: number): Observable<Blob> {
          let url = `${this.urlPrefix}/${dokumentId}/download`;
          return this.http.get(url, {responseType: 'blob'})
            .pipe(
              catchError(this.handleError<Blob>('downloadDokument'))
            );
  }

  saveDokument(id: number, file: File, filename: string, description: string): Observable<Object> {
    if (id) {
      return this.updateDokument(id, filename, description);
      // TODO update file if (file)
    } else {
      return this.uploadDokument(file, filename, description);
    }
  }

  private uploadDokument(file: File, filename: string, description: string) {
      let url = `${this.urlPrefix}/upload`;
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('filename', filename);
      formData.append('description', description);
      return this.http.post<Dokument>(url, formData, {reportProgress: false, responseType: 'json'})
          .pipe(
            catchError(this.handleError<Dokument>('uploadDokument'))
          );
  }

  private updateDokument(id: number, filename: string, description: string) {
      let url = `${this.urlPrefix}/update`;
      const formData: FormData = new FormData();
      formData.append('id', String(id));
      formData.append('filename', filename);
      formData.append('description', description);
      return this.http.post<Dokument>(url, formData, {responseType: 'json'})
          .pipe(
            catchError(this.handleError<Dokument>('uploadDokument'))
          );
  }

  deleteDokument(dokumentId: number): Observable<Object> {
        let url = `${this.urlPrefix}/${dokumentId}`;
        return this.http.delete(url, this.httpOptions)
          .pipe(
            catchError(this.handleError<Dokument>('deleteDokument'))
          );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.statusService.add(message);
  }
}
