import { Component, OnInit } from '@angular/core';

import { Saison } from '../saison/saison';
import { SaisonService } from '../saison/saison.service';

@Component({
  selector: 'app-resultate',
  templateUrl: './resultate.component.html',
  styleUrls: ['./resultate.component.scss']
})
export class ResultateComponent implements OnInit {

  saisonList: Saison[];

  constructor(
    private saisonService: SaisonService
  ) { }

  ngOnInit(): void {
    this.getSaisonList();
  }


  getSaisonList(): void {
    this.saisonService.getSaisonList$()
      .subscribe(saisonList =>
        this.saisonList = saisonList
      )
  }
}
