<div class="dokumentList container">
  <h2>Dokumente</h2>

  <div class="d-none d-sm-block">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-9 col-md-10">Dateiname / Beschreibung / Datum</th>
        <th class="col-3 col-md-2 text-center"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let dokument of dokumentList" class="d-flex">
        <td class="col-9 col-md-10">
          <div class="row m-0"> {{dokument.filename}}</div>
          <div class="row m-0 text-muted">{{dokument.description}}</div>
          <div class="row m-0 text-muted">{{dokument.datum | date: 'dd.MM.yyyy'}}</div>
        </td>
        <td class="col-3 col-md-2">
          <div class="row m-0 float-right">
            <button *ngIf="isMember() || isAdmin()" class="btn btn-secondary px-1 py-0">
              <fa-icon [icon]="faDownload" size="s" (click)="downloadDokument(dokument)"></fa-icon>
            </button>
            <a *ngIf="isAdmin()" class="btn btn-secondary px-1 py-0 ml-1"
               [routerLink]="['/dokumente', dokument.id]"
               [state]="{data: dokument}"
               routerLinkActive="active">
              <fa-icon [icon]="faEdit" size="s"></fa-icon>
            </a>
            <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 ml-1" (click)="deleteDokument(dokument.id)">
              <fa-icon [icon]="faTrash" size="s"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-sm-none">
    <table class="table table-sm">
      <thead>
      <tr class="d-flex">
        <th class="col-12">Dateiname / Beschreibung / Datum</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let dokument of dokumentList" class="d-flex">
        <td class="col-12">
          <div class="row m-0"> {{dokument.filename}}</div>
          <div class="row m-0 text-muted">{{dokument.description}}</div>
          <div class="row m-0 text-muted">{{dokument.datum | date: 'dd.MM.yyyy'}}</div>
          <button *ngIf="isMember() || isAdmin()" class="btn btn-secondary px-1 py-0">
            <fa-icon [icon]="faDownload" size="s" (click)="downloadDokument(dokument)"></fa-icon>
          </button>
          <a *ngIf="isAdmin()" class="btn btn-secondary px-1 py-0 ml-1"
             [routerLink]="['/dokumente', dokument.id]"
             [state]="{data: dokument}"
             routerLinkActive="active">
            <fa-icon [icon]="faEdit" size="s"></fa-icon>
          </a>
          <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 ml-1" (click)="deleteDokument(dokument.id)">
            <fa-icon [icon]="faTrash" size="s"></fa-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <a *ngIf="isAdmin()" class="btn btn-primary"
     [routerLink]="['/dokumente', 'new']"
     [state]="{}"
     routerLinkActive="active">
    <fa-icon [icon]="faPlus" size="s"></fa-icon>
  </a>
</div>
