import { Component, OnChanges, Input } from '@angular/core';
import { Saison } from '../../../saison/saison';
import { SaisonTurnier, SaisonTurnierTyp } from '../../../turnier/saison-turnier/saison-turnier';
import { SaisonTurnierService } from '../../../turnier/saison-turnier/saison-turnier.service';

@Component({
  selector: 'app-saison-turnier-overview',
  templateUrl: './saison-turnier-overview.component.html',
  styleUrls: ['./saison-turnier-overview.component.scss']
})
export class SaisonTurnierOverviewComponent implements OnChanges {

  @Input() saison: Saison;
  @Input() description: string;

  saisonTurniere: SaisonTurnier[];

  constructor(
    private saisonTurnierService: SaisonTurnierService
  ) { }

  ngOnChanges(): void {
    if (this.saison) {
      this.saisonTurnierService.getSaisonTurniere(this.saison.id)
        .subscribe(saisonTurniere => {
          this.saisonTurniere = saisonTurniere;
        })
    }
  }

  getRanglisteRouterLink(saisonTurnier: SaisonTurnier): string {
        switch(saisonTurnier.saisonTurnierTyp) {
          case 'RUNDEN_TURNIER':
            return `/saisons/${this.saison.id}/turniere/${saisonTurnier.id}/rundenturnier-detail`;

          case 'EINZEL_TURNIER_SERIE':
          case 'MULTI_DISZIPLINEN_CUP':
            return `/saisons/${this.saison.id}/turniere/${saisonTurnier.id}/turnierserie-detail`;
        }
  }

}
