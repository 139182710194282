import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KeycloakProfile } from "keycloak-js";
import { KeycloakService } from "keycloak-angular";

import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  profile: KeycloakProfile = undefined;
  userRoles: string[];
  isMember: boolean;
  isAdmin: boolean;

  constructor(private keycloak: KeycloakService) {
    // TODO Prüfen, ob es korrekt ist, diese Initialisierung im Konstruktor zum machen.
    this.keycloak.loadUserProfile()
      .then(profile => {
        console.log('profile', profile);
        this.profile = profile;
       })
      .catch(error => {
        console.log ('loadUserProfile() failed', error);
       });
    this.userRoles = this.keycloak.getUserRoles(true);
    this.isMember = this.keycloak.isUserInRole('ROLE_Member');
    this.isAdmin = this.keycloak.isUserInRole('ROLE_Admin');
  }

  login(): void {
    const loginOptions = {
       redirectUri: window.location.origin
    };
    this.keycloak.login(loginOptions);
  }

  logout(): void {
    this.keycloak.logout(window.location.origin);
  }

  isLoggedIn(): boolean {
    return this.profile !== undefined;
  }

  isUser(userid: string) {
    return this.profile && this.profile.username===userid;
  }

  getProfile(): KeycloakProfile {
    return this.profile;
  }
}
