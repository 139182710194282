import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TurnierSerie, Einzelturnier, EinzelturnierRangierung } from '../turnier-serie/turnier-serie';
import { StatusService } from '../../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class TurnierSerieService {

  private urlPrefix = 'api/turnier/turnierserie';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private statusService: StatusService
  ) { }

  getTurnierSerie(turnierId: number): Observable<TurnierSerie> {
      let url = `${this.urlPrefix}/${turnierId}`
      return this.http.get<TurnierSerie>(url)
        .pipe(
          catchError(this.handleError<TurnierSerie>('getTurnierSerie'))
        );
  }

  saveEinzelturnier(turnierserieId: number, einzelturnier: Einzelturnier): Observable<TurnierSerie> {
    let url = `${this.urlPrefix}/${turnierserieId}/einzelturnier`

    if (einzelturnier.id) {
      return this.http.put<TurnierSerie>(url, einzelturnier, this.httpOptions)
        .pipe(
          catchError(this.handleError<TurnierSerie>('updateEinzelturnier'))
        );

    } else {
      return this.http.post<TurnierSerie>(url, einzelturnier, this.httpOptions)
        .pipe(
          catchError(this.handleError<TurnierSerie>('addEinzelturnier'))
        );
    }
  }

  deleteEinzelturnier(turnierId: number, einzelturnierId: number): Observable<Object> {
      let url = `${this.urlPrefix}/${turnierId}/einzelturnier/${einzelturnierId}`
      return this.http.delete(url, this.httpOptions)
        .pipe(
          catchError(this.handleError<Einzelturnier>('deleteEinzelturnier'))
        );
  }

  saveRangierung(turnierserieId: number, einzelturnierId: number, rangierung: EinzelturnierRangierung): Observable<TurnierSerie> {
    let url = `${this.urlPrefix}/${turnierserieId}/einzelturnier/${einzelturnierId}/rangierung`

    if (rangierung.id) {
      return this.http.put<TurnierSerie>(url, rangierung, this.httpOptions)
        .pipe(
          catchError(this.handleError<TurnierSerie>('updateRangierung'))
        );

    } else {
      return this.http.post<TurnierSerie>(url, rangierung, this.httpOptions)
        .pipe(
          catchError(this.handleError<TurnierSerie>('addRangierung'))
        );
    }
  }

  deleteRangierung(turnierId: number, einzelturnierId: number, rangierungId: number): Observable<Object> {
      let url = `${this.urlPrefix}/${turnierId}/einzelturnier/${einzelturnierId}/rangierung/${rangierungId}`
      return this.http.delete(url, this.httpOptions)
        .pipe(
          catchError(this.handleError<EinzelturnierRangierung>('deleteRangierung'))
        );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.statusService.add(message);
  }
}
