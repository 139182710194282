import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { faSearch, faPaperclip } from '@fortawesome/free-solid-svg-icons';

import { Information } from '../information';
import { InformationService } from '../information.service';
import { Dokument } from '../../dokument/dokument';
import { DokumentService } from '../../dokument/dokument.service';

@Component({
  selector: 'app-information-edit',
  templateUrl: './information-edit.component.html',
  styleUrls: ['./information-edit.component.scss']
})
export class InformationEditComponent implements OnInit {

  information: Information;

  dokumentList: Dokument[];

  constructor(private router: Router, private route: ActivatedRoute, private informationService: InformationService, private dokumentService: DokumentService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
            const id = params['informationId'];
            if (isNaN(id)) {
              this.newInformation();
            } else {
              this.readInformation(+id);
            }
        }
      );

    this.readDokumentList();
  }

  private readInformation(id: number) {
      this.informationService.getInformation(id)
        .subscribe((information) => this.information = information);
  }

  private newInformation() {
      this.information = {
        id: null,
        message: '',
        style: 'info',
        displayBold: false,
        attachment: null,
        displayFrom: new Date() , // TODO
        displayTo: new Date('2099-12-31') // TODO
      };
  }

  readDokumentList(): void {
    this.dokumentService.getDokumentList()
      .subscribe(dokumentList => {
          this.dokumentList = [];
          this.dokumentList.push (null);
          Array.prototype.push.apply(this.dokumentList, dokumentList);
        }
      );
  }

  getDokumentList(): Dokument[] {
    return this.dokumentList;
  }

  compareById(dokument1: Dokument, dokument2: Dokument) {
    if (dokument1 && dokument2) {
      return dokument1.id == dokument2.id;
    } else {
      return false;
    }
  }

  onSubmit() {
    this.informationService.saveInformation(this.information).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['informationen']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }

  faSearch = faSearch;
  faPaperclip = faPaperclip;
}
