export class Rundenturnier {
  id: number;
  bezeichnung: String;
  turnierrunden: Turnierrunde[];
  rangliste: RundenturnierRangierung[];
}

export class Turnierrunde {
  id: number;
  runde: number;
  datum: Date;
  paarungen: Turnierpaarung[];
  canBeDeleted: boolean;
}

export class Turnierpaarung {
  id: number;
  weissPersonId: number;
  weissVorname: String;
  weissName: String;
  schwarzPersonId: number;
  schwarzVorname: String;
  schwarzName: String;
  resultat: PaarungsResultat;
  resultatBezeichnung: String;
  canBeDeleted: boolean;
}

export class RundenturnierRangierung {
     id: number;
     rang: number;
     personId: number;
     vorname: string;
     name: string;
     punkte: number;
     buchholz: number;
     feinwertung: number;
     anzahlAusstehendeResultate: number;
}


export enum PaarungsResultat {
    SIEG_WEISS = 'SIEG_WEISS',
    SIEG_WEISS_FORFAIT = 'SIEG_WEISS_FORFAIT',
    REMIS = 'REMIS',
    SIEG_SCHWARZ = 'SIEG_SCHWARZ',
    SIEG_SCHWARZ_FORFAIT = 'SIEG_SCHWARZ_FORFAIT',
    NULL_FORFAIT = 'NULL_FORFAIT',
    UNBEKANNT = 'UNBEKANNT'
}

