<div class="terminEdit container">

  <form (ngSubmit)="onSubmit()" #terminForm="ngForm">
    <div class="form-row">
      <div class="form-group col-3 col-sm-4 col-lg-2">
        <label for="wochentag">Wochentag</label>
        <input type="text" class="form-control" required
               id="wochentag"
               [(ngModel)]="termin.wochentag" name="wochentag">
      </div>
      <div class="form-group col-6 col-sm-4 col-lg-2">
        <label for="datum">Datum</label>
        <input type="date" class="form-control" required
               id="datum"
               [(ngModel)]="termin.datum" name="datum">
      </div>
      <div class="form-group col-3 col-sm-4 col-lg-2">
        <label for="zeit">Zeit</label>
        <input type="text" class="form-control" required
               id="zeit"
               [(ngModel)]="termin.zeit" name="zeit">
      </div>
      <div class="form-group col-12 col-lg-6">
        <label for="ort">Ort</label>
        <input type="text" class="form-control" required
               id="ort"
               [(ngModel)]="termin.ort" name="ort">
      </div>
    </div>
    <div class="form-group">
      <label for="anlass">Anlass</label>
      <input type="text" class="form-control" required
             id="anlass"
             [(ngModel)]="termin.anlass" name="anlass">
    </div>
    <div class="form-row">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" required
               id="juniorenschach"
               [(ngModel)]="termin.istJuniorenschach" name="istJuniorenschach">
        <label class="form-check-label" for="juniorenschach">Juniorenschach</label>
      </div>
    </div>
    <div class="form-group">
      <label for="bemerkung">Bemerkung</label>
      <textarea type="text" class="form-control" rows="3"
                id="bemerkung"
                placeholder="Bemerkung"
                [(ngModel)]="termin.bemerkung" name="bemerkung">
      </textarea>
    </div>
    <div class="form-group">
      <label for="alert">Alert</label>
      <select id="alert" class="form-control"
              [compareWith]="compareById"
              [(ngModel)]="termin.alert" name="alert">
        <option *ngFor="let information of informationList"
                [ngValue]="information">
          <span *ngIf="information">{{information.message}}</span>
          <span *ngIf="!information">(kein Alert)</span>
        </option>
      </select>
    </div>

    <a class="btn btn-outline-secondary" [routerLink]="['/saisons', saisonId, 'termine']">Abbrechen</a>
    <button class="btn btn-primary ml-1" type="submit">Speichern</button>
  </form>
</div>
