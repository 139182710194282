import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { initializeKeycloak } from './auth/keycloak-init.factory';

import { HeaderComponent } from './header/header.component';
import { StartComponent } from './start/start.component';
import { InformationComponent } from './information/information.component';
import { InformationOverviewComponent } from './start/information-overview/information-overview.component';
import { TerminOverviewComponent } from './start/termin-overview/termin-overview.component';
import { TurnierOverviewComponent } from './start/turnier-overview/turnier-overview.component';
import { TurnierComponent } from './turnier/turnier.component';
import { TurnierListComponent } from './turnier/turnier-list/turnier-list.component';
import { TerminListComponent } from './termin/termin-list/termin-list.component';
import { MitgliedListComponent } from './mitglied/mitglied-list/mitglied-list.component';
import { DokumentComponent } from './dokument/dokument.component';
import { DokumentListComponent } from './dokument/dokument-list/dokument-list.component';
import { InformationListComponent } from './information/information-list/information-list.component';
import { StatusComponent } from './status/status.component';
import { DokumentEditComponent } from './dokument/dokument-edit/dokument-edit.component';
import { InformationEditComponent } from './information/information-edit/information-edit.component';
import { InformationAlertComponent } from './information/information-alert/information-alert.component';
import { SaisonComponent } from './saison/saison.component';
import { SaisonListComponent } from './saison/saison-list/saison-list.component';
import { SaisonEditComponent } from './saison/saison-edit/saison-edit.component';
import { MitgliedEditComponent } from './mitglied/mitglied-edit/mitglied-edit.component';
import { MitgliedEloListComponent } from './mitglied/mitglied-elo-list/mitglied-elo-list.component';
import { TerminEditComponent } from './termin/termin-edit/termin-edit.component';
import { MitgliedEloChartComponent } from './mitglied/mitglied-elo-list/mitglied-elo-chart/mitglied-elo-chart.component';
import { LokalComponent } from './lokal/lokal.component';
import { KarteComponent } from './karte/karte.component';
import { TurnierEditComponent } from './turnier/turnier-edit/turnier-edit.component';
import { RundenTurnierDetailComponent } from './turnier/runden-turnier-detail/runden-turnier-detail.component';
import { TurnierSerieDetailComponent } from './turnier/turnier-serie-detail/turnier-serie-detail.component';
import { EinzelTurnierRanglisteComponent } from './turnier/turnier-serie-detail/einzel-turnier-rangliste/einzel-turnier-rangliste.component';
import { TurnierrundeComponent } from './turnier/runden-turnier-detail/turnierrunde/turnierrunde.component';
import { RundenturnierRanglisteComponent } from './turnier/runden-turnier-detail/rundenturnier-rangliste/rundenturnier-rangliste.component';
import { TurnierSerieRanglisteComponent } from './turnier/turnier-serie-detail/turnier-serie-rangliste/turnier-serie-rangliste.component';
import { MultidisziplinenCupRanglisteComponent } from './turnier/turnier-serie-detail/multidisziplinen-cup-rangliste/multidisziplinen-cup-rangliste.component';
import { RundenTurnierDetailEditComponent } from './turnier/runden-turnier-detail-edit/runden-turnier-detail-edit.component';
import { TurnierSerieDetailEditComponent } from './turnier/turnier-serie-detail-edit/turnier-serie-detail-edit.component';
import { TurnierRundeEditComponent } from './turnier/runden-turnier-detail-edit/turnier-runde-edit/turnier-runde-edit.component';
import { TurnierPaarungEditComponent } from './turnier/runden-turnier-detail-edit/turnier-paarung-edit/turnier-paarung-edit.component';
import { TurnierRundeDetailEditComponent } from './turnier/runden-turnier-detail-edit/turnier-runde-detail-edit/turnier-runde-detail-edit.component';
import { EinzelturnierDetailEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-detail-edit/einzelturnier-detail-edit.component';
import { EinzelturnierEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-edit/einzelturnier-edit.component';
import { EinzelturnierRangierungEditComponent } from './turnier/turnier-serie-detail-edit/einzelturnier-rangierung-edit/einzelturnier-rangierung-edit.component';
import { SaisonTurnierOverviewComponent } from './start/turnier-overview/saison-turnier-overview/saison-turnier-overview.component';
import { RanglisteOverviewComponent } from './start/turnier-overview/rangliste-overview/rangliste-overview.component';
import { AuthComponent } from './auth/auth.component';
import { VorstandComponent } from './vorstand/vorstand.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { JuniorenComponent } from './junioren/junioren.component';
import { ResultateComponent } from './resultate/resultate.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StartComponent,
    InformationComponent,
    InformationOverviewComponent,
    TerminOverviewComponent,
    TurnierOverviewComponent,
    TurnierComponent,
    TurnierListComponent,
    TerminListComponent,
    MitgliedListComponent,
    DokumentComponent,
    DokumentListComponent,
    DokumentEditComponent,
    InformationListComponent,
    StatusComponent,
    InformationEditComponent,
    InformationAlertComponent,
    SaisonComponent,
    SaisonListComponent,
    SaisonEditComponent,
    MitgliedEditComponent,
    MitgliedEloListComponent,
    TerminEditComponent,
    MitgliedEloChartComponent,
    LokalComponent,
    KarteComponent,
    TurnierEditComponent,
    RundenTurnierDetailComponent,
    TurnierSerieDetailComponent,
    EinzelTurnierRanglisteComponent,
    TurnierrundeComponent,
    RundenturnierRanglisteComponent,
    TurnierSerieRanglisteComponent,
    MultidisziplinenCupRanglisteComponent,
    RundenTurnierDetailEditComponent,
    TurnierSerieDetailEditComponent,
    TurnierRundeEditComponent,
    TurnierPaarungEditComponent,
    TurnierRundeDetailEditComponent,
    EinzelturnierDetailEditComponent,
    EinzelturnierEditComponent,
    EinzelturnierRangierungEditComponent,
    SaisonTurnierOverviewComponent,
    RanglisteOverviewComponent,
    AuthComponent,
    VorstandComponent,
    KontaktComponent,
    JuniorenComponent,
    ResultateComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
//     {
//       provide: HTTP_INTERCEPTORS,
//       useClass: AuthInterceptor,
//       multi: true
//     }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
