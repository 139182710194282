import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Kartenlayer, Weg, Punkt } from '../karte/kartenlayer';

@Component({
  selector: 'app-lokal',
  templateUrl: './lokal.component.html',
  styleUrls: ['./lokal.component.scss']
})
export class LokalComponent implements OnInit {

  constructor(private http: HttpClient) { }

  kartenlayer$: Observable<Kartenlayer>;
  ziel$: Observable<Punkt>;
  wege$: Observable<Weg[]>;

  ngOnInit(): void {
    this.kartenlayer$ = this.getKartenlayer$();
    this.ziel$ = this.getZiel$();
    this.wege$ = this.getWege$();
  }

  private getKartenlayer$(): Observable<Kartenlayer> {
    return this.http.get("assets/kartenlayer/spiellokal.json").pipe(
      map ((object: Kartenlayer) => {
        return {
          ziel: object.ziel,
          wege: object.wege
        } as Kartenlayer;
      }),
      shareReplay() // TODO To be tested
    );
  }

  getZiel$(): Observable<Punkt> {
    return this.kartenlayer$.pipe(
      map((kartenlayer) => kartenlayer.ziel)
    );
  }

  getWege$(): Observable<Weg[]> {
    return this.kartenlayer$.pipe(
      map((kartenlayer) => kartenlayer.wege)
    );
  }
}
