import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Termin } from './termin';
import { StatusService } from '../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class TerminService {

  private urlPrefix = '/api/termin';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private statusService: StatusService) { }

  getTerminOverviewList(numTurniere: number): Observable<Termin[]> {
    // TODO statt dessen getTerminList() verwenden
    let url = `${this.urlPrefix}/next?numTermine=${numTurniere}`;
    return this.http.get<Termin[]>(url)
      .pipe(
        catchError(this.handleError<Termin[]>('getTerminList', []))
      );
  }

  getTerminList(fkSaison: number): Observable<Termin[]> {
    let url = `${this.urlPrefix}?fkSaison=${fkSaison}`
    return this.http.get<Termin[]>(url)
      .pipe(
        catchError(this.handleError<Termin[]>('getTerminList', []))
      );
  }

  getTermin(terminId: number): Observable<Termin> {
    let url = `${this.urlPrefix}/${terminId}`;
    return this.http.get<Termin>(url)
      .pipe(
        catchError(this.handleError<Termin>('getTermin'))
      );
  }

  saveTermin(termin: Termin): Observable<Termin> {
    let url = `${this.urlPrefix}`;
    if (termin.id || termin.id===0) {
    return this.http.put<Termin>(url, termin, this.httpOptions)
        .pipe(
          catchError(this.handleError<Termin>('updateTermin'))
        );
    } else {
      return this.http.post<Termin>(url, termin, this.httpOptions)
          .pipe(
            catchError(this.handleError<Termin>('insertTermin'))
          );
    }
  }

  deleteTermin(terminId: number) {
    let url = `${this.urlPrefix}/${terminId}`;
    return this.http.delete(url, this.httpOptions)
      .pipe(
        catchError(this.handleError<Termin>('deleteTermin'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.statusService.add(message);
  }
}
