import { Component, OnInit } from '@angular/core';
import { Mitglied } from '../mitglied/mitglied';
import { MitgliedService } from '../mitglied/mitglied.service';
import { Termin } from '../termin/termin';
import { TerminService} from '../termin/termin.service';

@Component({
  selector: 'app-junioren',
  templateUrl: './junioren.component.html',
  styleUrls: ['./junioren.component.scss']
})
export class JuniorenComponent implements OnInit {

  kursleiter: Mitglied[];
  juniorenTermine: Termin[];

  constructor(private mitgliedService: MitgliedService, private terminService: TerminService) { }

  ngOnInit(): void {
      this.mitgliedService.getMitgliedList$().subscribe(
        mitgliedList => this.extractKursleiter(mitgliedList)
      );
      this.terminService.getTerminOverviewList(10).subscribe(
        terminList => this.juniorenTermine = terminList.filter(termin => termin.istJuniorenschach)
      );
  }

  private extractKursleiter(mitgliedList: Mitglied[]) {
    this.kursleiter = mitgliedList
      .filter(mitglied => mitglied.juniorenLeiter);
  }

  phone(mitglied: Mitglied): string {
    return (mitglied.telMobil)?
      mitglied.telMobil:
      (mitglied.telPrivat)?
        mitglied.telPrivat:
        mitglied.telGeschaeft;
  }

  getDescription(): string {
    return 'Der Schachklub Düdingen führt jedes Jahr einen Schachkus für Juniorinnen und Junioren durch. '
    + 'Vorkenntnisse sind dafür nicht notwendig. Selbstverständlich dürfen die Eltern dem Schachkurs beiwohnen.';
  }

  getModus(): string {
    return 'Der Junioren-Schachkurs findet jeden Dienstag während der Schulzeit statt und macht während den Schulferien Pause.';
  }

  getKursperiode(): string {
    return 'Jede Woche während Schulzeit, keine Kurse während Schulferien';
  }

  getAnmeldung(): string {
    return 'Nicht erforderlich. Interessierte können einfach am ersten Kurstag erscheinen.';
  }

  getAnmeldung2(): string {
    return '(Es ist auch möglich, während dem Schuljahr zu beginnen. In diesem Fall bitte den Kursleiter kontaktieren)';
  }

  getTeilnehmer(): string {
    return 'offen für Kinder und Jugendliche ab 7 Jahren';
  }

  getKosten(): string {
    return 'Übungsheft (CHF 10.-)';
  }
}
