import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common'

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { Rundenturnier, Turnierrunde, Turnierpaarung, PaarungsResultat } from '../../runden-turnier/rundenturnier';
import { RundenturnierService } from '../../runden-turnier/rundenturnier.service';
import { MitgliedService } from '../../../mitglied/mitglied.service';
import { Mitglied } from '../../../mitglied/mitglied';

@Component({
  selector: 'app-turnier-paarung-edit',
  templateUrl: './turnier-paarung-edit.component.html',
  styleUrls: ['./turnier-paarung-edit.component.scss']
})
export class TurnierPaarungEditComponent implements OnInit {

  saison: Saison;
  rundenturnier: Rundenturnier;
  turnierrunde: Turnierrunde;
  mitgliedList: Mitglied[];
  paarungsResultatList: PaarungsResultat[]

  paarungId: number;
  weissPersonId: number;
  weissVorname: string;
  weissName: string;
  schwarzPersonId: number;
  schwarzVorname: string;
  schwarzName: string;
  resultat: PaarungsResultat;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private saisonService: SaisonService,
    private mitgliedService: MitgliedService,
    private rundenturnierService: RundenturnierService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          let turnierrundeId = params['turnierrundeId'];
          let paarungId = params['paarungId']
          this.readSaison(+saisonId);
          this.readRundenTurnier(+turnierId, turnierrundeId, paarungId);
          this.readMitgliedList();
        }
      );

    this.paarungsResultatList = Object.keys(PaarungsResultat).map(key => PaarungsResultat[key]);
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readRundenTurnier(turnierId: number, turnierrundeId: number, paarungId: number): void {
    this.rundenturnierService.getRundenturnier(turnierId)
      .subscribe(
        rundenturnier => {
          this.rundenturnier = rundenturnier;
            this.turnierrunde = rundenturnier.turnierrunden.find(turnierrunde => turnierrunde.id==turnierrundeId);

          if (!isNaN(paarungId)) {
            this.paarungId = paarungId;
            let paarung: Turnierpaarung = this.turnierrunde.paarungen.find(paarung => paarung.id==paarungId);
            this.weissPersonId = paarung.weissPersonId;
            this.schwarzPersonId = paarung.schwarzPersonId;
            this.resultat = paarung.resultat;
          }
        }
      );
  }

  readMitgliedList(): void {
    this.mitgliedService.getMitgliedList$().subscribe(
      mitgliedList => this.mitgliedList = mitgliedList.filter(mitglied => mitglied.isAktiv)
    );
  }

  name(personId: number): string {
    let mitglied = this.mitglied(personId);
    if (mitglied) {
      return `${mitglied.vorname} ${mitglied.name}`;
    } else {
      return '';
    }
  }

  private mitglied(personId: number): Mitglied {
    return this.mitgliedList.find(mitglied => mitglied.id === personId);
  }

  onSubmit() {
    let personWeiss = this.mitglied(this.weissPersonId);
    let personSchwarz = this.mitglied(this.schwarzPersonId);
    let paarung: Turnierpaarung = {
      id: this.paarungId,
      weissPersonId: this.weissPersonId,
      weissVorname: personWeiss?.vorname,
      weissName: personWeiss?.name,
      schwarzPersonId: this.schwarzPersonId,
      schwarzVorname: personSchwarz?.vorname,
      schwarzName: personSchwarz?.name,
      resultat: this.resultat,
      resultatBezeichnung: '',
      canBeDeleted: true
    };

    this.rundenturnierService.saveTurnierPaarung(this.rundenturnier.id, this.turnierrunde.id, paarung).subscribe({
        next: data => {
          console.log("Save successful");
          this.back();
        },
        error: error => {
          console.error("Save error: ", error.message);
          // TODO Status-Service
        }
    });
  }

  back(): void {
    this.location.back();
  }
}
