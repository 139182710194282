import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Saison } from '../../../saison/saison';
import { SaisonService } from '../../../saison/saison.service';
import { TurnierSerie, Einzelturnier, EinzelturnierRangierung } from '../../turnier-serie/turnier-serie';
import { TurnierSerieService } from '../../turnier-serie/turnier-serie.service';
import { StatusService } from '../../../status/status.service';

@Component({
  selector: 'app-einzelturnier-detail-edit',
  templateUrl: './einzelturnier-detail-edit.component.html',
  styleUrls: ['./einzelturnier-detail-edit.component.scss']
})
export class EinzelturnierDetailEditComponent implements OnInit {

  saison: Saison;
  turnierserie: TurnierSerie;
  einzelturnier: Einzelturnier;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private saisonService: SaisonService,
      private turnierSerieService: TurnierSerieService,
      private statusService: StatusService
  ) { }

  ngOnInit(): void {
      this.route.params
        .subscribe(
          (params) => {
            let saisonId = params['saisonId'];
            let turnierId = params['turnierId'];
            let einzelturnierId = params['einzelturnierId'];
            this.readSaison(+saisonId);
            this.readTurnierSerie(+turnierId, einzelturnierId);
          }
        );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

    readTurnierSerie(turnierId: number, einzelturnierId: number): void {
      this.turnierSerieService.getTurnierSerie(turnierId)
        .subscribe(
          turnierserie => {
            this.turnierserie = turnierserie;
            this.einzelturnier = turnierserie.einzelturniere.find(einzelturnier => einzelturnier.id==einzelturnierId);
          }
        );
    }

    deleteRangierung(rangierung: EinzelturnierRangierung) {
      this.turnierSerieService.deleteRangierung(this.turnierserie.id, this.einzelturnier.id, rangierung.id)
          .subscribe({
            next: data => {
              console.log("Delete successful");
              this.readTurnierSerie(this.turnierserie.id, this.einzelturnier.id);
            },
            error: error => {
              console.error("Delete error: ", error.message);
              this.logError(`Delete failed: ${error.message}`);
            }
          });
    }

    private logError(message: string) {
      this.statusService.add(message);
    }

    faPlus = faPlus;
    faEdit = faEdit;
    faTrash = faTrash;
    faArrowCircleLeft = faArrowCircleLeft;
}
