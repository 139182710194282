<div class="container">
  <h2>Mitteilungen</h2>
  <table class="table table-sm">
    <thead>
    <tr class="d-flex">
      <th class="col-12">Anzeige / Information / Attachment</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let information of informationList" class="d-flex">
      <td class="col-12">
        <div class="row m-0">
          <span class="col-8 col-md-10 p-0 m-0">{{information.displayFrom | date: 'dd.MM.yyyy'}} - {{information.displayTo| date: 'dd.MM.yyyy'}}</span>
          <span class="col-4 col-md-2 mb-2">
          <button class="btn btn-danger px-1 py-0 ml-1 float-right" (click)="deleteInformation(information.id)">
            <fa-icon [icon]="faTrash" size="s"></fa-icon>
          </button>
          <a class="btn btn-secondary px-1 py-0 ml-1 float-right"
             [routerLink]="['/informationen', information.id]"
             routerLinkActive="active">
            <fa-icon [icon]="faEdit" size="s"></fa-icon>
          </a>
          </span>
        </div>
        <app-information-alert [information]="information"></app-information-alert>
      </td>
    </tr>
    </tbody>
  </table>
  <a class="btn btn-primary"
     [routerLink]="['/informationen', 'new']"
     routerLinkActive="active">
    <fa-icon [icon]="faPlus" size="s"></fa-icon>
  </a>
</div>
