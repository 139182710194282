<div class="turnierSerieDetail container" *ngIf="turnierSerie">
  <h3>{{turnierSerie.bezeichnung}} <span class="d-none d-sm-inline">({{saison.bezeichnung}})</span></h3>
  <div  class="py-1" *ngFor="let einzelturnier of turnierSerie.einzelturniere">
    <app-einzel-turnier-rangliste [einzelturnier]="einzelturnier"></app-einzel-turnier-rangliste>
  </div>
  <app-turnier-serie-rangliste *ngIf="isKurzzeitTurnierSerie()" [rangliste]="turnierSerie.rangliste"></app-turnier-serie-rangliste>
  <app-multidisziplinen-cup-rangliste *ngIf="isMultidisziplinenCup()" [rangliste]="turnierSerie.rangliste"></app-multidisziplinen-cup-rangliste>
  <a class="btn btn-secondary btn-sm mt-2"
     title="Zurück"
     (click)="back()">
    <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
  </a>
</div>
