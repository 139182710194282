import { Component, OnInit } from '@angular/core';
import { faPaperclip, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Information } from '../information';
import { InformationService } from '../information.service';
import { StatusService } from '../../status/status.service';

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html',
  styleUrls: ['./information-list.component.scss']
})
export class InformationListComponent implements OnInit {

  informationList: Information[];

  constructor(private informationService: InformationService, private statusService: StatusService) { }

  ngOnInit(): void {
    this.getInformationList();
  }

  getInformationList(): void {
    this.informationService.getInformationList()
      .subscribe(informationList =>
        this.informationList = informationList
      )
  }

  deleteInformation(informationId: number) {
    this.informationService.deleteInformation(informationId)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.getInformationList();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      });
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPaperclip = faPaperclip;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
}
