import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { Rundenturnier } from '../runden-turnier/rundenturnier';
import { RundenturnierService } from '../runden-turnier/rundenturnier.service';


@Component({
  selector: 'app-runden-turnier-detail',
  templateUrl: './runden-turnier-detail.component.html',
  styleUrls: ['./runden-turnier-detail.component.scss']
})
export class RundenTurnierDetailComponent implements OnInit {

  saison: Saison;
  rundenturnier: Rundenturnier;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private saisonService: SaisonService,
    private rundenturnierService: RundenturnierService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params) => {
          let saisonId = params['saisonId'];
          let turnierId = params['turnierId'];
          this.readSaison(+saisonId);
          this.readRundenTurnier(+turnierId);
        }
      );
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.saison = saison;
      });
  }

  readRundenTurnier(turnierId: number): void {
    this.rundenturnierService.getRundenturnier(turnierId)
      .subscribe(
        rundenturnier => {
          this.rundenturnier = rundenturnier;
        }
      );
  }

  back(): void {
      this.location.back();
  }

  faArrowCircleLeft = faArrowCircleLeft;
}
