import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Turnierrunde, Turnierpaarung } from '../../runden-turnier/rundenturnier';
import { AuthService } from '../../../auth/auth.service';
import { MitgliedService } from '../../../mitglied/mitglied.service';
import { Mitglied } from '../../../mitglied/mitglied';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-turnierrunde',
  templateUrl: './turnierrunde.component.html',
  styleUrls: ['./turnierrunde.component.scss']
})
export class TurnierrundeComponent implements OnInit {

  @Input() turnierrunde: Turnierrunde;

  mitgliedList: Mitglied[];
  saisonId: number;
  turnierId: number;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private mitgliedService: MitgliedService
  ) { }

  ngOnInit(): void {
    this.mitgliedService.getMitgliedList$().subscribe(mitgliedList => this.mitgliedList = mitgliedList);

    this.route.params
      .subscribe(
        (params) => {
          this.saisonId = params['saisonId'];
          this.turnierId = params['turnierId'];
        }
      );
  }

  isMember(): boolean {
    return this.authService.isMember;
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  private paarungBelongsToAuthorizedUser(paarung: Turnierpaarung): boolean {
    return this.mitgliedBelongsToAuthorizedUser(paarung.weissPersonId) || this.mitgliedBelongsToAuthorizedUser(paarung.schwarzPersonId);
  }

  private mitgliedBelongsToAuthorizedUser(mitgliedId: number) {
    if (mitgliedId === undefined || this.mitgliedList === undefined) {
      return false;
    }

    const mitglied = this.mitgliedList
      .find(mitglied => mitglied.id === mitgliedId);

    if (mitglied === undefined) {
      return false;
    }

    return this.authService.isUser(mitglied.userid);
  }

  faEdit = faEdit;

}
