import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Information } from './information';
import { StatusService } from '../status/status.service';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  private urlPrefix = '/api/information';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private statusService: StatusService) { }

  getInformationList(): Observable<Information[]> {
    let url = `${this.urlPrefix}`;
    return this.http.get<Information[]>(url)
      .pipe(
        catchError(this.handleError<Information[]>('getInformationList', []))
      );
  }

  getDisplayInformations(): Observable<Information[]> {
    let url = `${this.urlPrefix}/display`;
    return this.http.get<Information[]>(url)
      .pipe(
        catchError(this.handleError<Information[]>('getInformationList', []))
      );
   }

  getInformation(informationId: number): Observable<Information> {
      let url = `${this.urlPrefix}/${informationId}`;
      return this.http.get<Information>(url)
        .pipe(
          catchError(this.handleError<Information>('getInformation'))
        );
  }

  saveInformation(information: Information): Observable<Information> {

      let url = `${this.urlPrefix}`;
      if (information.id || information.id===0) {
      return this.http.put<Information>(url, information, this.httpOptions)
          .pipe(
            catchError(this.handleError<Information>('updateInformation'))
          );
      } else {
        return this.http.post<Information>(url, information, this.httpOptions)
            .pipe(
              catchError(this.handleError<Information>('insertInformation'))
            );
      }
  }

  deleteInformation(informationId: number): Observable<Object> {
        let url = `${this.urlPrefix}/${informationId}`;
        return this.http.delete(url, this.httpOptions)
          .pipe(
            catchError(this.handleError<Information>('deleteInformation'))
          );
  }

  private handleError<T>(operation = 'operation', result?: T) { // TODO: in separate Komponente aufnehmen
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      this.logError(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private logError(message: string) {
    this.statusService.add(message);
  }
}
