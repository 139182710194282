<div class="container">
  <h3>Elozahlen von {{mitglied.vorname}} {{mitglied.name}} (aktuell:
    <span *ngIf="mitglied.elo">{{mitglied.elo}}</span>
    <span *ngIf="!mitglied.elo">keine Wertung</span>
    )
  </h3>
  <div class="row">
    <app-mitglied-elo-chart class="col-12" [mitglied]="mitglied" [elozahlen]="(elozahlen$ | async)" [saisonList]="(saisonList$ | async)"></app-mitglied-elo-chart>
  </div>
  <table class="table table-sm">
    <thead>
    <tr class="d-flex">
      <th class="col-3">Saison</th>
      <th class="col-3">Elo</th>
      <th class="col-1">
        <button class="btn btn-sm btn-primary">
          <fa-icon [icon]="faPlus" size="s" (click)="addElozahl()"></fa-icon>
        </button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let elozahl of elozahlen" class="d-flex">
      <td class="col-3">
        <select id="fkSaison" class="form-control"
                [(ngModel)]="elozahl.fkSaison" name="fkSaison">
          <option *ngFor="let saison of saisonList" [ngValue]="saison.id">
            {{saison.bezeichnung}}
          </option>
        </select>

      </td>
      <td class="col-3">
        <input type="text" class="form-control" placeholder="Elo"
               [(ngModel)]="elozahl.elo" name="elo">
      </td>
      <td class="col-1">
        <button class="btn btn-sm btn-danger">
          <fa-icon [icon]="faTrash" size="s" (click)="deleteElozahl(elozahl.id)"></fa-icon>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div><strong>TODO: Elo-Liste speichern ist noch nicht implementiert</strong></div>
  <a class="btn btn-primary"
     [routerLink]="['/mitglieder', mitglied.id]"
     [state]="{data: mitglied}">Schliessen</a>
</div>
