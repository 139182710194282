import { KeycloakService } from "keycloak-angular";


export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://www.senseschach.ch/auth',
        realm: 'senseschach',
        clientId: 'senseschach-client'
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        checkLoginIframe: false
      },
      bearerExcludedUrls: []
    });
}
