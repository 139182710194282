import { Component, OnInit } from '@angular/core';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Mitglied } from '../mitglied';
import { MitgliedService } from '../mitglied.service';
import { StatusService } from '../../status/status.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-mitglied-list',
  templateUrl: './mitglied-list.component.html',
  styleUrls: ['./mitglied-list.component.scss']
})
export class MitgliedListComponent implements OnInit {

  mitgliedList: Mitglied[];
  showInactive: boolean = false;

  constructor(private mitgliedService: MitgliedService, private statusService: StatusService, private authService: AuthService) { }

  ngOnInit(): void {
    this.getMitgliedList();
  }

  getMitgliedList(): void {
    this.mitgliedService.getMitgliedList$()
      .subscribe(mitgliedList =>
        this.mitgliedList = mitgliedList
      )
  }

  isMember(): boolean {
    return this.authService.isMember;
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  showMemberDetails(): boolean {
    return this.isMember() || this.isAdmin();
  }

  belongsToUser(mitglied: Mitglied): boolean {
    return this.authService.isUser(mitglied.userid);
  }

  canBeDeleted(mitglied: Mitglied): boolean {
    return true; // TODO
  }

  deleteMitglied(mitgliedId: number) {
    this.mitgliedService.deleteMitglied$(mitgliedId)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.getMitgliedList();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      });
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
}
