import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Mitglied } from '../mitglied';
import { MitgliedService } from '../mitglied.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-mitglied-edit',
  templateUrl: './mitglied-edit.component.html',
  styleUrls: ['./mitglied-edit.component.scss']
})
export class MitgliedEditComponent implements OnInit {

  mitglied: Mitglied;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mitgliedService: MitgliedService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
      this.route.params
        .subscribe(
          (params: Params) => {
              const mitgliedId = params['mitgliedId'];
              if (isNaN(mitgliedId)) {
                this.newMitglied();
              } else {
                this.readMitglied(+mitgliedId);
              }
          }
        );
   }

  isMember(): boolean {
    return this.authService.isMember;
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  private readMitglied(mitgliedId: number) {
      this.mitgliedService.getMitglied$(mitgliedId)
        .subscribe((mitglied) => this.mitglied = mitglied);
  }

  private newMitglied() {
      this.mitglied = {
          id: null,
          ssbNummer: null,
          vorname: null,
          name: null,
          adresse: null,
          plz: null,
          ort: null,
          telPrivat: null,
          telMobil: null,
          telGeschaeft: null,
          email: null,
          userid: null,
          isAktiv: true,
          elo: null,
          elozahlen: [],
          showContactDetails: false,
          juniorenLeiter: false,
          vorstandsrolle: null,
          vorstandsrolleDescription: null
      };
  }

  onSubmit() {
    this.mitgliedService.saveMitglied$(this.mitglied).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['mitglieder']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
