import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { Mitglied } from '../../mitglied';
import { Elozahl } from '../../elozahl';
import { Saison } from '../../../saison/saison';


import * as d3 from 'd3';

@Component({
  selector: 'app-mitglied-elo-chart',
  templateUrl: './mitglied-elo-chart.component.html',
  styleUrls: ['./mitglied-elo-chart.component.scss']
})
export class MitgliedEloChartComponent implements OnInit, OnChanges {

  @Input() mitglied: Mitglied;
  @Input() elozahlen: Elozahl[];
  @Input() saisonList: Saison[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (this.elozahlen && this.saisonList) {
      this.draw();
    }
  }

  private draw() {
    const svg = d3.select("svg");

    var chartWidth = parseInt(svg.style("width"));
    var chartHeight = parseInt(svg.style("height"));

    const margin = {top:10, right:60, bottom:40, left:40};
    const graphWidth = chartWidth - margin.left - margin.right;
    const graphHeight = chartHeight - margin.top - margin.bottom;

    const chartArea = svg.append("g")
      .attr("width", graphWidth)
      .attr("height", graphHeight)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    var scaleX = d3.scalePoint()
      .domain(this.elozahlen.map(e => this.saisonLabel(e.fkSaison)))
      .range([0, graphWidth]);

     const minElo = d3.min(this.elozahlen, (d) => d.elo);
     const maxElo = d3.max(this.elozahlen, (d) => d.elo);
     var scaleY = d3.scaleLinear()
      .domain([
        Math.round((minElo - 100) / 50) * 50,
        Math.round((maxElo + 50) / 50) * 50,
      ])
      .range([graphHeight, 0]);

     var yAxis = d3
      .axisLeft(scaleY)
      .ticks(4);

    var xAxis = d3
      .axisBottom(scaleX);

      chartArea
        .append("g")
        .attr("transform", `translate(0, ${graphHeight})`)
        .call(xAxis);

      chartArea
        .append("g")
        .call(yAxis);

     const eloLine = d3.line<Elozahl>()
        .x((d: Elozahl, i) => scaleX(this.saisonLabel(d.fkSaison)))
        .y((d: Elozahl, i) => scaleY(d.elo));
     const eloPath = chartArea
        .append("path")
        .data([this.elozahlen])
        .attr("d", eloLine)
        .attr("stroke", "lightgrey")
        .attr("fill", "white");

     const eloCircles = chartArea
        .append("g")
          .selectAll("circle")
          .data(this.elozahlen);

     eloCircles.enter()
        .append("circle")
        .attr("class", "circle")
        .attr("cx", (d, i) => scaleX(this.saisonLabel(d.fkSaison)))
        .attr("cy", (d, i) => scaleY(d.elo))
        .attr("r", 3)
        .attr("stroke", (d, i) => (d.elo===maxElo ? "red": "black"))
        .attr("fill", (d, i) => (d.elo===maxElo ? "red": "black"));

     const eloLabels = chartArea
         .append("g")
         .selectAll("text")
         .data(this.elozahlen);

     eloLabels.enter()
        .append("text")
        .text((d, i) => String(d.elo))
        .attr("x", (d, i) => scaleX(this.saisonLabel(d.fkSaison)) + 5)
        .attr("y", (d, i) => scaleY(d.elo) + 15)
        .attr("stroke", (d, i) => (d.elo===maxElo ? "red": "black"))
        .style("font-size", "10px");
  }

  private saisonLabel(saisonFk: number): string {
    return this.saisonList.find(saison => saison.id === saisonFk).bezeichnung;
  }
}
