import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Termin } from '../termin';
import { TerminService } from '../termin.service';
import { Saison } from '../../saison/saison';
import { SaisonService } from '../../saison/saison.service';
import { StatusService } from '../../status/status.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-termin-list',
  templateUrl: './termin-list.component.html',
  styleUrls: ['./termin-list.component.scss']
})
export class TerminListComponent implements OnInit {

  terminList: Termin[] = [];

  saisonList: Saison[];
  selectedSaison: Saison;
  currentSaisonId: number;

  constructor(
    private route: ActivatedRoute,
    private terminService: TerminService,
    private saisonService: SaisonService,
    private statusService: StatusService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.route.params
            .subscribe(
              (params: Params) => {
                  const saisonId = params['saisonId'];
                  if (isNaN(saisonId)) {
                    this.readCurrentSaison();
                  } else {
                    this.readSaison(+saisonId);
                  }
              }
            );

    this.readSaisonList();
  }

  isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  readSaisonList(): void {
    this.saisonService.getSaisonList$()
      .subscribe(saisonList => {
        this.saisonList = saisonList;
      });
  }

  readSaison(saisonId: number): void {
    this.saisonService.getSaison(saisonId)
      .subscribe(saison => {
        this.selectedSaison = saison;
        this.readTerminList();
      });
  }

  readCurrentSaison(): void {
      this.saisonService.getCurrentSaison()
        .subscribe(currentSaison => {
          if (currentSaison) {
            this.currentSaisonId = currentSaison.id;
            this.readSaison(this.currentSaisonId);
          }
        });
  }

  readTerminList(): void {
    this.terminService.getTerminList(this.selectedSaison.id)
      .subscribe(terminList => {
        this.terminList = terminList;
      });
  }

  onSaisonChange(): void {
    this.readTerminList();
  }

  canBeDeleted(termin: Termin): boolean {
    // TODO 'false' falls Termin aus eine bereits vergangenen Saison
    return true;
  }

  deleteTermin(terminId: number) {
    this.terminService.deleteTermin(terminId)
      .subscribe({
        next: data => {
          console.log("Delete successful");
          this.readTerminList();
        },
        error: error => {
          console.error("Delete error: ", error.message);
          this.logError(`Delete failed: ${error.message}`);
        }
      });
  }

  compareById(saison1: Saison, saison2: Saison) {
    if (saison1 && saison2) {
      return saison1.id == saison2.id;
    } else {
      return false;
    }
  }

  private logError(message: string) {
    this.statusService.add(message);
  }

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
}
