import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Saison } from '../saison';
import { SaisonService } from '../saison.service';

@Component({
  selector: 'app-saison-edit',
  templateUrl: './saison-edit.component.html',
  styleUrls: ['./saison-edit.component.scss']
})
export class SaisonEditComponent implements OnInit {

  saison: Saison;

  constructor(private router: Router, private route: ActivatedRoute, private saisonService: SaisonService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
            const id = params['saisonId'];
            if (isNaN(id)) {
              this.newSaison();
            } else {
              this.readSaison(+id);
            }
        }
      );
  }

  private readSaison(id: number) {
    this.saisonService.getSaison(id)
      .subscribe((saison) => this.saison = saison);
  }

  private newSaison() {
      this.saison = {
        id: null,
        startDatum: new Date(), // TODO
        endDatum: new Date(), // TODO
        bezeichnung: null
      };
  }

  onSubmit() {
    this.saisonService.saveSaison(this.saison).subscribe({
      next: data => {
        console.log("Save successful");
        this.router.navigate(['saisons']);
      },
      error: error => {
        console.error("Save error: ", error.message);
        // TODO Status-Service
      }
    });
  }
}
