<div class="turnierrundeDetailEdit container" *ngIf="saison && rundenturnier && turnierrunde">
  <h3 class="my-4">
    <span class="d-none d-md-inline">{{rundenturnier.bezeichnung}} ({{saison.bezeichnung}}) - </span>
    <span>Runde {{turnierrunde.runde}}</span>
    <span class="d-none d-sm-inline"> ({{turnierrunde.datum | date: 'dd.MM.yyyy'}})</span>
  </h3>

  <table class="table table-sm">
    <thead>
    <tr class="d-flex">
      <th class="col-3 col-sm-4">Weiss</th>
      <th class="col-1"></th>
      <th class="col-3 col-sm-4">Schwarz</th>
      <th class="col-2 col-sm-1">Resultat</th>
      <th class="col-3 col-sm-2"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let paarung of turnierrunde.paarungen" class="d-flex">
      <td class="col-3 col-sm-4">{{paarung.weissVorname}} {{paarung.weissName}}</td>
      <td class="col-1">
        <span *ngIf="paarung.schwarzPersonId">-</span>
      </td>
      <td class="col-3 col-sm-4">
        <span *ngIf="paarung.schwarzPersonId">{{paarung.schwarzVorname}} {{paarung.schwarzName}}</span>
        <span *ngIf="!paarung.schwarzPersonId" class="text-muted"><em>spielfrei</em></span>
      </td>
      <td class="col-2 col-sm-1">
        <span *ngIf="paarung.schwarzPersonId">{{paarung.resultatBezeichnung}}</span>
      </td>
      <td class="col-3 col-sm-2">
        <button *ngIf="isAdmin()" class="btn btn-danger px-1 py-0 ml-1 float-right" [disabled]="!paarung.canBeDeleted" (click)="deletePaarung(paarung.id)" title="Löschen">
          <fa-icon [icon]="faTrash" size="s"></fa-icon>
        </button>
        <a *ngIf="isAdmin() || belongsToPaarung(paarung)" class="btn btn-secondary px-1 py-0 ml-1 float-right"
           title="Editieren"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'turnierrunde', turnierrunde.id, 'paarung', paarung.id]"
           routerLinkActive="active">
          <fa-icon [icon]="faEdit" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    <tr class="d-flex">
      <td class="col-3 col-sm-4">
        <a *ngIf="saison" class="btn btn-primary btn-sm"
           title="Paarung hinzufügen"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'turnierrunde', turnierrunde.id, 'paarung', 'new']"
           routerLinkActive="active">
          <fa-icon [icon]="faPlus" size="s"></fa-icon>
        </a>
      </td>
      <td class="col-1"></td>
      <td class="col-3 col-sm-4"></td>
      <td class="col-2 col-sm-1"></td>
      <td class="col-3 col-sm-2">
        <a *ngIf="saison" class="btn btn-secondary btn-sm float-right"
           title="Zurück"
           [routerLink]="['/saisons', saison.id, 'turniere', rundenturnier.id, 'rundenturnier-detail-edit']"
           routerLinkActive="active">
          <fa-icon [icon]="faArrowCircleLeft" size="s"></fa-icon>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
